<template>
  <div
    v-if="data !== undefined"
    class="mb-6">
    <div class="flex flex-wrap gap-8 pt-8 mb-6 overflow-auto">
      <div class="field">
        <span class="p-float-label">
          <InputText
            id="employer"
            v-model="data.employer" />
          <label for="employer">Employer</label>
        </span>
      </div>

      <div class="field">
        <span class="p-float-label">
          <InputText
            id="role"
            v-model="data.role" />
          <label for="role">Role</label>
        </span>
      </div>

      <div class="field">
        <span class="p-float-label">
          <InputText
            id="location"
            v-model="data.location" />
          <label for="employer">Location</label>
        </span>
      </div>

      <div class="field">
        <span class="p-float-label">
          <Calendar
            id="fromDate"
            v-model="data.fromDate" />
          <label for="fromDate">From date</label>
        </span>
      </div>

      <div class="field">
        <span class="p-float-label">
          <Calendar
            id="tillDate"
            v-model="data.tillDate" />
          <label for="tillDate">Till date</label>
        </span>
      </div>
    </div>

    <div class="field w-40 h-40 max-h-40 max-w-40 mb-6">
      <image-upload v-model="data.employerIcon.path" />
    </div>

    <div class="field mx-auto">
      <span class="p-float-label h-fit">
        <TextArea
          class="w-full h-60"
          id="description"
          v-model="data.description" />
        <label for="description">Description</label>
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ImageUpload } from "@ui/components"
  import InputText from "primevue/inputtext"
  import TextArea from "primevue/textarea"
  import Calendar from "primevue/calendar"
  import { storeToRefs } from "pinia"
  import { useListStore } from "../list/list.store"
  const store = useListStore()
  const { item: data } = storeToRefs(store)
</script>
