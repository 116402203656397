import { createAppAuthStorage } from "@home/web-services"
import { defineStore } from "pinia"
import { ref } from "vue"

const storage = createAppAuthStorage()

export const useAuthStore = defineStore("admin/dashboard", {
  state: async () => ({
    isLoggedIn: ref(await storage.isLoggedIn()),
  }),
  actions: {
    async logout() {
      await storage.signOut()
      this.$router.replace({ path: "/login" })
    },
    goTo(path: any) {
      this.$router.replace({ path: path })
    },
  },
})
