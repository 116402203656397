import { ToastMessageOptions } from "primevue/toast"

const timeout = 3000

export const successToast = (message?: string): ToastMessageOptions => ({
  severity: "success",
  summary: "Success",
  detail: message,
  life: timeout,
})

export const errorToast = (message?: string): ToastMessageOptions => ({
  severity: "error",
  summary: "Error",
  detail: message,
  life: timeout,
})

export const infoToast = (message?: string): ToastMessageOptions => ({
  severity: "info",
  summary: "Info",
  detail: message,
  life: timeout,
})
