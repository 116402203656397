import { authApp } from "@config/firebase/src/init-firebase"
import {
  setPersistence,
  signInWithEmailAndPassword,
  signOut,
  browserLocalPersistence,
  browserSessionPersistence,
} from "firebase/auth"

export interface AppAuthStorage {
  signIn: (user: string, pass: string, rememberMe: boolean) => Promise<boolean>
  signOut: () => Promise<boolean>
  isLoggedIn: () => Promise<boolean>
}

export const createAppAuthStorage = (): AppAuthStorage => ({
  signIn: async (
    user: string,
    pass: string,
    rememberMe: boolean,
  ): Promise<boolean> => {
    try {
      await setPersistence(
        authApp(),
        rememberMe ? browserLocalPersistence : browserSessionPersistence,
      )
      await signInWithEmailAndPassword(authApp(), user, pass)
      return true
    } catch (error) {
      console.error(error)
    }
    return false
  },
  signOut: async (): Promise<boolean> => {
    try {
      await signOut(authApp())
      return true
    } catch (error) {
      console.error(error)
    }
    return false
  },
  isLoggedIn: async (): Promise<boolean> => {
    const checkAuthStatus = async (): Promise<boolean> =>
      new Promise((resolve, _) => {
        authApp().onAuthStateChanged(user => {
          resolve(user !== undefined && user !== null)
        })
      })
    return await checkAuthStatus()
  },
})
