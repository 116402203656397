<template>
  <div class="flex flex-col">
    <Sidebar v-model:visible="menuToggle">
      <side-nav-vue />
    </Sidebar>
    <div
      class="mt-6 ml-6"
      @click="menuToggle = !menuToggle">
      <v-icon
        name="fa-hamburger"
        scale="1.8" />
      <span class="ml-2">Menu</span>
    </div>
    <router-view />
  </div>
</template>

<script setup lang="ts">
  import Sidebar from "primevue/sidebar"
  import sideNavVue from "@/ui/dashboard/side-nav.vue"
  import { ref } from "vue"
  const menuToggle = ref<boolean>(false)
</script>
