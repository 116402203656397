import ProjectTable from "@/ui/projects/projects-table.vue"
import ProjectEdit from "@/ui/projects/project-edit.vue"
import { RouteRecordRaw } from "vue-router"

export const projectRoutes: RouteRecordRaw[] = [
  {
    path: "",
    component: ProjectTable,
  },
  {
    path: ":id",
    component: ProjectEdit,
  },
]
