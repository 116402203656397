import { v4 } from "uuid"

export class BlogPost {
  constructor(
    public readonly id: string = v4(),
    public readonly title: string = "",
    public readonly content: string = "",
    public readonly summary: string = "",
    public readonly publishedAt: Date = new Date(),
    public readonly tags: string[] = [],
    public readonly status: BlogPostStatus = "Draft",
    public readonly readCount: number = 0,
  ) {}
}

export class Comment {
  constructor(
    public readonly id: string = v4(),
    public readonly blogPostId: string = "",
    public readonly owner: string = "",
    public readonly message: string = "",
  ) {}
}

export type BlogPostStatus = "Published" | "Draft"
