<template>
  <div class="rounded-2xl border border-zinc-200 p-6 dark:border-zinc-700/40">
    <div class="flex gap-4 items-center">
      <v-icon
        name="fa-code"
        scale="1.5"
        class="text-zinc-400" />
      <h2 class="text-sm font-semibold text-zinc-900 dark:text-zinc-100">
        Technologies
      </h2>
    </div>
    <div class="grid grid-cols-4 mt-6 gap-8">
      <div
        v-for="technology in props.data"
        v-bind:key="technology.name"
        class="dark:hover:bg-zinc-800 hover:bg-zinc-200/50 rounded-lg -m-2 p-2 cursor-pointer">
        <technology-item
          @click="goToTechDetails(technology)"
          :data="technology" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { Technology } from "@home/domain"
  import technologyItem from "./technology-item.vue"
  import { useHomePageStore } from "../home.store"
  const props = defineProps<{ data: Technology[] }>()
  const store = useHomePageStore()
  const { goToTechDetails } = store
</script>
