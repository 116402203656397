import { errorToast, successToast } from "@/shared/toast-message"
import { Technology } from "@home/domain"
import { createTechnologiesStorage } from "@home/web-services"
import { isEmpty } from "lodash"
import { defineStore } from "pinia"

interface TechnologiesState {
  technologies: Technology[]
  selected: Technology[]

  item?: Technology
  isNewItem: boolean

  error?: Error
}

const defaultState: TechnologiesState = {
  technologies: [],
  selected: [],
  item: undefined,
  isNewItem: false,
  error: undefined,
}

const storage = createTechnologiesStorage()

export const useTechnologiesStore = defineStore("home/web-admin/technologies", {
  state: (): TechnologiesState => defaultState,
  getters: {
    isEditDisabled: state => state.selected.length !== 1,
    isDeleteDisabled: state => state.selected.length === 0,
  },
  actions: {
    async init() {
      await this.fetch()
    },

    async fetch() {
      try {
        this.technologies = (await storage.getAll()) as Technology[]
      } catch (error) {
        console.error(error)
      }
    },
    async newItem() {
      this.isNewItem = true
      this.item = new Technology()
      this.selected = []
      this.$router.push({ path: "/admin/technologies/new" })
    },
    async editItem() {
      this.isNewItem = true
      this.item = this.selected[0]
      this.selected = []
      this.$router.push({ path: `/admin/technologies/${this.item.id}` })
    },
    async deleteItem() {
      await this.delete(this.selected)
      this.selected = []
    },
    async rowReorder(ordered: Technology[]) {
      await storage.updateOrder(ordered)
      await this.fetch()
    },
    async save() {
      if (this.isNewItem && !isEmpty(this.item)) {
        await this.create(this.item)
      } else if (!isEmpty(this.item)) {
        await this.update(this.item)
      }
      await this.cancel()
    },
    async cancel() {
      this.isNewItem = false
      this.item = undefined
      this.$router.back()
    },
    async create(technology: Technology) {
      try {
        await storage.create(technology)
        this.technologies = [...this.technologies, technology]
        this.$toast.add(successToast())
      } catch (error) {
        this.$toast.add(errorToast(error.message))
        console.error(error)
      }
    },
    async update(technology: Technology) {
      try {
        await storage.update(technology)
        this.technologies = [
          ...this.technologies.filter(it => it.id === technology.id),
          technology,
        ]
        this.$toast.add(successToast())
      } catch (error) {
        this.$toast.add(errorToast(error.message))
        console.error(error)
      }
    },
    async delete(technologies: Technology[]) {
      try {
        await storage.delete(technologies)
        this.technologies = [
          ...this.technologies.filter(it => !technologies.includes(it)),
        ]
        this.$toast.add(successToast())
      } catch (error) {
        this.$toast.add(errorToast(error.message))
        console.error(error)
      }
    },
  },
})
