import { firestoreApp } from "@config/firebase"
import { Lead } from "@home/domain"
import {
  collection,
  updateDoc,
  query,
  getDocs,
  doc,
  deleteDoc,
  setDoc,
  getDoc,
  Timestamp,
} from "firebase/firestore"
import { v4, validate } from "uuid"

interface LeadsStorage {
  getAll: () => Promise<Lead[] | Error>
  getById: (id: string) => Promise<Lead | Error>
  create: (lead: Lead) => Promise<Lead | Error>
  update: (lead: Lead) => Promise<Lead | Error>
  delete: (lead: Lead[]) => Promise<void | Error>
}

export const createLeadsStorage = (
  _collection = "/home/content",
  _document = "/leads",
): LeadsStorage => ({
  getAll: async () => await getAll(_collection, _document),
  getById: async (id: string) => await getById(id, _collection, _document),
  create: async (lead: Lead) => await create(lead, _collection, _document),
  update: async (lead: Lead) => await update(lead, _collection, _document),
  delete: async (leads: Lead[]) => await deletes(leads, _collection, _document),
})

async function getAll(
  _collection: string,
  _document: string,
): Promise<Lead[] | Error> {
  const queryRef = query(collection(firestoreApp(), _collection, _document))
  try {
    const docSnapshot = await getDocs(queryRef)
    return docSnapshot.docs.map(doc => doc.data()).map(fromFirebase)
  } catch (error) {
    console.error(error)
    return Error(error.message)
  }
}

async function getById(
  id: string,
  _collection: string,
  _document: string,
): Promise<Lead | Error> {
  const docRef = doc(firestoreApp(), _collection, _document, id)
  try {
    const doc = await getDoc(docRef)
    return fromFirebase(doc.data())
  } catch (error) {
    console.error(error)
    return Error(error.message)
  }
}

async function create(
  lead: Lead,
  _collection: string,
  _document: string,
): Promise<Lead | Error> {
  const collRef = collection(firestoreApp(), _collection, _document)
  try {
    const document = toFirebase(lead)
    const docRef = doc(collRef, document.id)
    await setDoc(docRef, document)
    return fromFirebase(document)
  } catch (error) {
    console.error(error)
    return Error(error)
  }
}

async function update(
  lead: Lead,
  _collection: string,
  _document: string,
): Promise<Lead | Error> {
  const docRef = doc(firestoreApp(), _collection, _document, lead.id)
  try {
    const doc = toFirebase(lead)
    await updateDoc(docRef, doc)
    const from = fromFirebase(doc)
    return from
  } catch (error) {
    return Error(error)
  }
}

async function deletes(
  leads: Lead[],
  _collection: string,
  _document: string,
): Promise<void | Error> {
  for (const lead of leads) {
    const docRef = doc(firestoreApp(), _collection, _document, lead.id)
    try {
      await deleteDoc(docRef)
    } catch (error) {
      console.error(error)
      return Error(error)
    }
  }
  return
}

const toFirebase = (lead: Lead) => {
  const { id, date, ...rest } = lead
  const validatedId = validate(id) ? id : v4()
  return {
    id: validatedId,
    date: Timestamp.fromDate(date),
    ...rest,
  }
}

const fromFirebase = (lead: any): Lead => {
  const { date, ...rest } = lead
  return {
    date: date.toDate() ?? new Date(),
    ...rest,
  }
}
