import { FirebaseApp, deleteApp, initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"
import { getAuth } from "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyCSWwExeOhtuxeFsvZhsXiZlEQxmOdON5M",
  authDomain: "my-world-0690.firebaseapp.com",
  databaseURL:
    "https://my-world-0690-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "my-world-0690",
  storageBucket: "my-world-0690.appspot.com",
  messagingSenderId: "367700046064",
  appId: "1:367700046064:web:3c3a0d649d1e08e016d235",
}

let app: FirebaseApp

export const initializeFirebase = () => (app = initializeApp(firebaseConfig))
export const deIntitializeFirebase = () => deleteApp(app)

export const firestoreApp = () => getFirestore(app)
export const storageApp = () => getStorage(app)
export const authApp = () => getAuth(app)
