<template>
  <div
    class="p-6"
    v-if="detail !== undefined">
    <action-buttons class="mb-6" />

    <div class="flex flex-col gap-4">
      <div>
        <div class="font-bold">From</div>
        <div>{{ detail.email }}</div>
      </div>
      <div>
        <div class="font-bold">At</div>
        <div>{{ detail.date.toLocaleDateString() }}</div>
      </div>
      <div>
        <div class="font-bold">Subject</div>
        <div>{{ detail.subject }}</div>
      </div>
      <div>
        <div class="font-bold">Message</div>
        <div>{{ detail.message }}</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import actionButtons from "./action-buttons.vue"
  import { storeToRefs } from "pinia"
  import { useLeadsStore } from "./leads.store"
  const store = useLeadsStore()
  const { detail } = storeToRefs(store)
</script>
