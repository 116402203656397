<template>
  <div
    v-if="item !== undefined"
    class="overflow-hidden flex flex-col flex-grow p-6">
    <h1 class="text-2xl mb-6 dark:text-zinc-400">
      {{ isNewItem ? "Create" : "Update" }} technology
    </h1>

    <action-buttons />

    <div class="flex flex-wrap gap-8 pt-8 mb-8 overflow-auto">
      <div class="field">
        <span class="p-float-label">
          <InputText
            id="name"
            v-model="item.name" />
          <label for="name">Name</label>
        </span>
      </div>

      <div class="field">
        <span class="p-float-label">
          <InputNumber
            id="percentage"
            v-model="item.percentage" />
          <label for="percentage">Percentage</label>
        </span>
      </div>
    </div>

    <div class="flex flex-col mb-8">
      <div class="mb-2">Logo</div>
      <image-upload
        class="rounded min-w-fit border-black max-w-xs w-32 h-32"
        v-model="item.icon.path" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import InputText from "primevue/inputtext"
  import InputNumber from "primevue/inputnumber"
  import { ImageUpload } from "@ui/components"
  import actionButtons from "./action-buttons.vue"
  import { useTechnologiesStore } from "./technologies.store"
  import { storeToRefs } from "pinia"
  const store = useTechnologiesStore()
  const { item, isNewItem } = storeToRefs(store)
</script>
