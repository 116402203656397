import { Home, HomeRoutes } from "@home/web"
import { Blog, BlogRoutes } from "@blog/web"
import { AdminDashboard, AdminLogin, adminRoutes } from "@admin/web"
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import { createAppAuthStorage } from "@home/web-services"

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    component: Home,
    children: HomeRoutes,
  },
  {
    path: "/blog",
    component: Blog,
    children: BlogRoutes,
  },
  {
    path: "/admin",
    component: AdminDashboard,
    children: adminRoutes,
  },
  {
    path: "/login",
    component: AdminLogin,
  },
]

export const router = createRouter({
  history: createWebHistory(),
  routes: routes,
  scrollBehavior(to, _, _0) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      }
    } else if (to.fullPath !== "/") {
      return { top: 0 }
    }
  },
})

const authStorage = createAppAuthStorage()

router.beforeEach(async (to, _, next) => {
  const isAdminLogged = await authStorage.isLoggedIn()
  const isRouteProtected = to.fullPath.includes("admin")
  if (isRouteProtected && !isAdminLogged) {
    next({ path: "/login" })
  } else {
    next()
  }
})
