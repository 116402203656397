<template>
  <div class="flex flex-col w-full overflow-scroll p-6">
    <h1 class="text-2xl mb-6 dark:text-zinc-400">Manage blog posts</h1>

    <div class="flex flex-row gap-2 mb-6">
      <Button
        label="New"
        class="h-10"
        icon="pi pi-plus"
        severity="success"
        size="small"
        @click="newItem" />
      <Button
        label="Edit"
        class="h-10"
        icon="pi pi-pencil"
        severity="info"
        :disabled="isEditDisabled"
        size="small"
        @click="editItem" />
      <Button
        label="Delete"
        class="h-10"
        icon="pi pi-trash"
        severity="danger"
        :disabled="isDeleteDisabled"
        size="small"
        @click="deleteItems" />
    </div>

    <DataTable
      ref="dt"
      dataKey="id"
      :value="blogPosts"
      v-model:selection="selected">
      <Column
        selectionMode="multiple"
        :exportable="false" />
      <Column
        field="title"
        header="Title" />
      <Column
        field="summary"
        header="Summary" />
      <Column
        field="status"
        header="Status" />
      <Column
        field="publishedAt"
        header="Published at"
        sortable>
        <template #body="{ data }">{{
          data.publishedAt.toDateString()
        }}</template>
      </Column>
    </DataTable>
  </div>
</template>

<script setup lang="ts">
  import Button from "primevue/button"
  import DataTable from "primevue/datatable"
  import Column from "primevue/column"
  import { useBlogStore } from "../store"
  import { storeToRefs } from "pinia"
  import { onMounted } from "vue"
  const store = useBlogStore()
  const { blogPosts, selected, isEditDisabled, isDeleteDisabled } =
    storeToRefs(store)
  const { init, newItem, editItem, deleteItems } = store
  onMounted(init)
</script>

<style scoped>
  :deep(.p-datatable .p-datatable-tbody > tr > td) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: auto;
    max-width: 12rem;
  }
  :deep(.p-datatable .p-datatable-tbody > .p-datatable-row-expansion > td) {
    padding: 0px;
    margin: 0px;
  }
</style>
