<template>
  <div class="flex h-screen flex-col justify-between dark:bg-zinc-900">
    <div class="px-4">
      <nav
        aria-label="Main Nav"
        class="mt-6 flex flex-col space-y-1">
        <details class="group group-open [&_summary::-webkit-details-marker]">
          <summary
            class="flex cursor-pointer items-center justify-between rounded-lg px-4 py-2 text-gray-500 hover:bg-gray-100 hover:text-gray-700">
            <div class="flex items-center gap-2">
              <v-icon
                name="fa-home"
                scale="1.5" />
              <span class="text-sm font-medium"> Home </span>
            </div>
            <span
              class="shrink-0 transition duration-300 group-open:-rotate-180">
            </span>
          </summary>

          <nav
            aria-label="Teams Nav"
            class="mt-2 flex flex-col px-4">
            <a
              class="flex items-center gap-2 rounded-lg px-4 py-2 text-gray-500 hover:bg-gray-100 hover:text-gray-700"
              @click="store.goTo('/admin/introduction')">
              <v-icon
                name="fa-address-card"
                scale="1.5" />
              <span class="text-sm font-medium"> Introduction </span>
            </a>

            <a
              class="flex items-center gap-2 rounded-lg px-4 py-2 text-gray-500 hover:bg-gray-100 hover:text-gray-700"
              @click="store.goTo('/admin/employments')">
              <v-icon
                name="fa-algolia"
                scale="1.5" />
              <span class="text-sm font-medium"> Employments </span>
            </a>

            <a
              class="flex items-center gap-2 rounded-lg px-4 py-2 text-gray-500 hover:bg-gray-100 hover:text-gray-700"
              @click="store.goTo('/admin/upload-cv')">
              <v-icon
                name="fa-file-upload"
                scale="1.5" />
              <span class="text-sm font-medium"> Upload Cv </span>
            </a>

            <a
              class="flex items-center gap-2 rounded-lg px-4 py-2 text-gray-500 hover:bg-gray-100 hover:text-gray-700"
              @click="store.goTo('/admin/projects')">
              <v-icon
                name="fa-briefcase"
                scale="1.5" />
              <span class="text-sm font-medium"> Projects </span>
            </a>

            <a
              class="flex items-center gap-2 rounded-lg px-4 py-2 text-gray-500 hover:bg-gray-100 hover:text-gray-700"
              @click="store.goTo('/admin/technologies')">
              <v-icon
                name="fa-stack-overflow"
                scale="1.5" />
              <span class="text-sm font-medium"> Technologies </span>
            </a>

            <a
              class="flex items-center gap-2 rounded-lg px-4 py-2 text-gray-500 hover:bg-gray-100 hover:text-gray-700"
              @click="store.goTo('/admin/leads')">
              <v-icon
                name="fa-person-booth"
                scale="1.5" />
              <span class="text-sm font-medium"> Leads </span>
            </a>
          </nav>
        </details>

        <details class="group [&_summary::-webkit-details-marker]">
          <summary
            class="flex cursor-pointer items-center justify-between rounded-lg px-4 py-2 text-gray-500 hover:bg-gray-100 hover:text-gray-700">
            <div class="flex items-center gap-2">
              <v-icon
                name="fa-blog"
                scale="1.5" />
              <span class="text-sm font-medium"> Blog </span>
            </div>
            <span
              class="shrink-0 transition duration-300 group-open:-rotate-180">
            </span>
          </summary>

          <nav
            aria-label="Teams Nav"
            class="mt-2 flex flex-col px-4">
            <a
              class="flex items-center gap-2 rounded-lg px-4 py-2 text-gray-500 hover:bg-gray-100 hover:text-gray-700"
              @click="store.goTo('/admin/blog/new')">
              <v-icon
                name="fa-plus-circle"
                scale="1.5" />
              <span class="text-sm font-medium"> Create post </span>
            </a>

            <a
              class="flex items-center gap-2 rounded-lg px-4 py-2 text-gray-500 hover:bg-gray-100 hover:text-gray-700"
              @click="store.goTo('/admin/blog')">
              <v-icon
                name="fa-leanpub"
                scale="1.5" />
              <span class="text-sm font-medium"> Manage posts </span>
            </a>
          </nav>
        </details>

        <summary
          @click="store.logout()"
          class="flex cursor-pointer items-center justify-between rounded-lg px-4 py-2 text-gray-500 hover:bg-gray-100 hover:text-gray-700">
          <div class="flex items-center gap-2">
            <v-icon
              name="fa-sign-out-alt"
              scale="1.5"
              class="text-red-500" />
            <span class="text-sm font-medium"> Logout </span>
          </div>

          <span class="shrink-0 transition duration-300 group-open:-rotate-180">
          </span>
        </summary>
      </nav>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useAuthStore } from "./admin.store"
  const store = useAuthStore()
</script>
