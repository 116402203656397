<template>
  <transition>
    <div
      v-if="show"
      class="fixed top-0 left-0 flex flex-1 justify-end md:justify-center p-6 w-full z-20 pointer-events-none">
      <div
        class="fixed inset-x-4 top-8 z-50 origin-top rounded-3xl bg-white p-8 ring-1 ring-zinc-900/5 dark:bg-zinc-900 dark:ring-zinc-800 opacity-100 scale-100 pointer-events-auto"
        tabindex="-1"
        v-if="open">
        <div class="flex flex-row-reverse items-center justify-between">
          <button
            aria-label="Close menu"
            class="-m-1 p-1"
            type="button"
            @click="open = !open"
            v-if="open">
            <svg
              viewBox="0 0 24 24"
              aria-hidden="true"
              class="h-6 w-6 text-zinc-500 dark:text-zinc-400">
              <path
                d="m17.25 6.75-10.5 10.5M6.75 6.75l10.5 10.5"
                fill="none"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"></path>
            </svg>
          </button>
          <h2 class="text-sm font-medium text-zinc-600 dark:text-zinc-400">
            Navigation
          </h2>
        </div>
        <nav class="mt-6">
          <ul
            class="-my-2 divide-y divide-zinc-100 text-base text-zinc-800 dark:divide-zinc-100/5 dark:text-zinc-300">
            <li>
              <router-link
              :class="checkExpandedStyle('/#about')"
                to="/#about"
                >About</router-link
              >
            </li>
            <li>
              <router-link
              :class="checkExpandedStyle('/#technologies')"
                to="/#technologies"
                >Technologies</router-link
              >
            </li>
            <li>
              <router-link
              :class="checkExpandedStyle('/#teams')"
                to="/#teams"
                >Teams</router-link
              >
            </li>
            <li>
              <router-link
              :class="checkExpandedStyle('/#projects')"
                to="/#projects"
                >Projects</router-link
              >
            </li>
            <li>
              <router-link
              :class="checkExpandedStyle('/#contact')"
                to="/#contact"
                >Contact</router-link
              >
            </li>
            <li>
              <router-link
              :class="checkExpandedStyle('/#blog')"
                to="/blog"
                >Blog</router-link
              >
            </li>
          </ul>
        </nav>
      </div>
      <div class="pointer-events-auto md:hidden pr-6">
        <button
          class="group flex items-center rounded-full bg-white/90 px-4 py-2 text-sm font-medium text-zinc-800 shadow-lg shadow-zinc-800/5 ring-1 ring-zinc-900/5 backdrop-blur dark:bg-zinc-800/90 dark:text-zinc-200 dark:ring-white/10 dark:hover:ring-white/20"
          type="button"
          aria-expanded="false"
          @click="toggleMenu"
          id="headlessui-popover-button-:R2miqla:">
          Menu<svg
            viewBox="0 0 8 6"
            aria-hidden="true"
            class="ml-3 h-auto w-2 stroke-zinc-500 group-hover:stroke-zinc-700 dark:group-hover:stroke-zinc-400">
            <path
              d="M1.75 1.75 4 4.25l2.25-2.5"
              fill="none"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"></path>
          </svg>
        </button>
      </div>
      <div
        style="
          position: fixed;
          top: 1px;
          left: 1px;
          width: 1px;
          height: 0;
          padding: 0;
          margin: -1px;
          overflow: hidden;
          clip: rect(0, 0, 0, 0);
          white-space: nowrap;
          border-width: 0;
          display: none;
        "></div>
      <nav class="pointer-events-auto hidden md:block">
        <ul
          class="flex rounded-full bg-white/90 px-3 text-sm font-medium text-zinc-800 shadow-lg shadow-zinc-800/5 ring-1 ring-zinc-900/5 backdrop-blur dark:bg-zinc-800/90 dark:text-zinc-200 dark:ring-white/10">
          <li>
            <router-link
            :class="checkExpandedStyle('/#about')"
              to="/#about"
              >About</router-link
            >
          </li>
          <li>
            <router-link
            :class="checkExpandedStyle('/#technologies')"
              to="/#technologies"
              >Tech</router-link
            >
          </li>
          <li>
            <router-link
            :class="checkExpandedStyle('/#teams')"
              to="/#teams"
              >Teams</router-link
            >
          </li>
          <li>
            <router-link
            :class="checkExpandedStyle('/#projects')"
              to="/#projects"
              >Projects</router-link
            >
          </li>
          <li>
            <router-link
              :class="checkExpandedStyle('/#contact')"
              to="/#contact"
              >Contact</router-link
            >
          </li>
          <li>
            <router-link
              :class="checkExpandedStyle('/blog')"
              to="/blog"
              >Blog</router-link
            >
          </li>
        </ul>
      </nav>
    </div>
  </transition>
</template>

<script setup lang="ts">
  import { RouterLink, useRoute } from "vue-router"
  import { ref, onBeforeMount, onUnmounted, watch } from "vue"

  const show = ref<boolean>(true)
  const allwaysHide = ref<boolean>(false)
  const open = ref<boolean>(false)
  const offset = ref<number>(0)

  onBeforeMount(() => window.addEventListener("scroll", handleScroll))
  onUnmounted(() => window.removeEventListener("scroll", handleScroll))

  const handleScroll = () => (offset.value = window.scrollY)
  watch(offset, (oldVal, newVal) => {
    show.value = newVal - oldVal > 0 && !allwaysHide.value
    open.value = false
  })

  const toggleMenu = () => (open.value = !open.value)

  const route = useRoute()

  const expandedStyle = "relative block px-3 py-2 transition"
  const expandedSelectedStyle = expandedStyle + "font-bold text-teal-500"

  const checkExpandedStyle = (path: string) => route.fullPath.includes(path) ? expandedSelectedStyle : expandedStyle

  watch(route, (oldVal, newVal) => {
    allwaysHide.value = newVal.fullPath.includes("admin") || newVal.fullPath.includes("login")
    show.value = !allwaysHide.value
  })


</script>

<style scoped>
  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }
  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
</style>
