<template>
  <div class="flex flex-row flex-wrap gap-4 pr-10 pl-10 p-6">
    <div
      v-for="tag of tags"
      v-bind:key="tag">
      <Chip
        :class="style(tag)"
        :label="tag"
        @click="selectTag(tag)" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import Chip from "primevue/chip"
  import { useBlogStore } from "../store"
  import { storeToRefs } from "pinia"

  const store = useBlogStore()
  const selected =
    "border border-zinc-600 hover:border hover:border-zinc-400 bg-teal-500 font-bold cursor-pointer"
  const unselected =
    "border border-zinc-600 hover:border hover:border-zinc-400 cursor-pointer"
  const style = (name: string) =>
    selectedTags.value.includes(name) ? selected : unselected
  const { tags, selectedTags } = storeToRefs(store)
  const { selectTag } = store
</script>
