<template>
  <nav-menu />
  <div
    class="container mx-auto bg-zinc-200/25 dark:bg-zinc-900 2xl:max-w-[70%] xl:max-w-[75%] lg:max-w-[80%]">
    <toast />
    <router-view />
  </div>
</template>

<script setup lang="ts">
  import { onMounted } from "vue"
  import { darkModeSetup } from "./assets/themes/dark-mode-setup"
  import navMenu from "./components/nav-menu.vue"
  onMounted(darkModeSetup)
</script>
