import { RouteRecordRaw } from "vue-router"
import LeadList from "@/ui/leads/list.page.vue"
import LeadDetails from "@/ui/leads/details.page.vue"

export const leadsRoutes: RouteRecordRaw[] = [
  {
    path: "",
    component: LeadList,
  },
  {
    path: ":id",
    component: LeadDetails,
  },
]
