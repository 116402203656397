import { RouteRecordRaw } from "vue-router"
import BlogList from "@/ui/blog-list/page.vue"
import BlogPost from "@/ui/blog-post/page.vue"

export const BlogRoutes: RouteRecordRaw[] = [
  {
    path: "",
    component: BlogList,
  },
  {
    path: ":id",
    component: BlogPost,
  },
]
