<template>
  <div class="flex flex-col w-full p-6">
    <h1 class="text-2xl mb-6 dark:text-zinc-400">
      Update introduction section
    </h1>

    <div class="grid grid-cols-2 gap-4 w-full">
      <div class="flex flex-col gap-12 pt-6">
        <div class="field">
          <span class="p-float-label">
            <InputText
              class="w-full"
              id="title"
              v-model="introduction.title" />
            <label for="title">Title</label>
          </span>
        </div>

        <div class="field grow">
          <span class="p-float-label h-full">
            <TextArea
              class="w-full h-full"
              id="description"
              v-model="introduction.description" />
            <label for="description">Description</label>
          </span>
        </div>
      </div>

      <div class="">
        <image-upload v-model="introduction.photo.path" />
      </div>
    </div>

    <Button
      class="mt-6"
      label="Update"
      severity="info"
      @click="store.update()" />
  </div>
</template>

<script setup lang="ts">
  import { ImageUpload } from "@ui/components"
  import InputText from "primevue/inputtext"
  import TextArea from "primevue/textarea"
  import Button from "primevue/button"
  import { useIntroductionStore } from "./introduction.store"
  import { storeToRefs } from "pinia"
  const store = useIntroductionStore()
  const { introduction } = storeToRefs(store)
</script>
