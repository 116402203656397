import { BlogPost } from "@blog/domain"
import { createBlogPostStorage } from "@blog/web-services"
import { defineStore } from "pinia"

interface BlogState {
  blogPosts: BlogPost[]
  filteredBlogPosts: BlogPost[]
  blogPostDetails?: BlogPost
  isLoading: boolean
  error?: Error
  tags: string[]
  selectedTags: string[]
}

const defaultState: BlogState = {
  blogPosts: [],
  filteredBlogPosts: [],
  isLoading: false,
  blogPostDetails: undefined,
  error: undefined,
  tags: [],
  selectedTags: [],
}

const storage = createBlogPostStorage()

export const useBlogStore = defineStore("blog/web", {
  state: () => defaultState,
  getters: {
    getBlogPostById: state => (id: string) =>
      state.blogPosts.find(it => it.id === id),
  },
  actions: {
    async init() {
      await this.fetch()
    },
    async fetch() {
      try {
        this.isLoading = true
        this.blogPosts = ((await storage.getAll()) as BlogPost[]).sort(
          sortDescending,
        )
        this.filteredBlogPosts = this.blogPosts
        this.tags = [...new Set(this.blogPosts.flatMap(it => it.tags))]
      } catch (error) {
        console.error(error)
      } finally {
        this.isLoading = false
      }
    },
    async goTo(id: string) {
      this.blogPostDetails = this.getBlogPostById(id)
      this.$router.push({ path: `/blog/${id}` })
    },
    async checkBlogPostDetails() {
      if (!this.blogPostDetails) {
        const routeId = this.$router.currentRoute.value.params.id as string
        await this.fetch()
        this.blogPostDetails = this.getBlogPostById(routeId)
      }
    },
    selectTag(tag: string) {
      if (this.selectedTags.includes(tag)) {
        this.selectedTags = this.selectedTags.filter(it => it !== tag)
      } else {
        this.selectedTags = [tag, ...this.selectedTags]
      }
      if (this.selectedTags.length > 0) {
        this.filteredBlogPosts = this.blogPosts.filter(
          it => it.tags.filter(it => this.selectedTags.includes(it)).length > 0,
        )
      } else {
        this.filteredBlogPosts = this.blogPosts
      }
    },
    back() {
      this.$router.back()
    },
  },
})

const sortDescending = (a: BlogPost, b: BlogPost) =>
  b.publishedAt.getTime() - a.publishedAt.getTime()
