<template>
  <div
    class="flex flex-col gap-8 p-6 min-h-screen"
    v-if="store.blogPost !== undefined">
    <div class="text-2xl font-bold">
      {{ store.isNew ? "Create" : "Update" }} article
    </div>

    <div class="flex flex-wrap gap-6">
      <Button
        class="w-32"
        label="Save"
        severity="info"
        @click="store.saveItem" />
      <Button
        class="w-32"
        label="Cancel"
        severity="info"
        @click="store.cancelItem" />
    </div>

    <div class="field flex-1 min-w-60">
      <span class="p-float-label">
        <InputText
          class="w-full"
          id="title"
          v-model="store.blogPost.title" />
        <label for="title">Title</label>
      </span>
    </div>

    <div class="flex flex-wrap gap-8">
      <div class="field flex-1 min-w-60">
        <span class="p-float-label">
          <Calendar
            class="w-full"
            id="publish"
            v-model="store.blogPost.publishedAt" />
          <label for="publish">Published at</label>
        </span>
      </div>

      <ToggleButton
        v-model="publishState"
        class="flex-1 min-w-60"
        onIcon="pi pi-check"
        onLabel="Published"
        offLabel="Draft"
        offIcon="pi pi-times" />
    </div>

    <div class="field w-full">
      <span class="p-float-label">
        <TextArea
          class="w-full"
          id="summary"
          v-model="store.blogPost.summary" />
        <label for="summary">Summary</label>
      </span>
    </div>

    <div class="field">
      <span class="p-float-label">
        <Chips
          id="tags"
          v-model="store.blogPost.tags" />
        <label for="tags">Tags</label>
      </span>
    </div>

    <ckeditor
      class="min-h-screen"
      :editor="editor"
      v-model="store.blogPost.content" />
  </div>
</template>

<script setup lang="ts">
  window.global ||= window
  import Calendar from "primevue/calendar"
  import InputText from "primevue/inputtext"
  import TextArea from "primevue/textarea"
  import Chips from "primevue/chips"
  import ToggleButton from "primevue/togglebutton"
  import { useBlogStore } from "../store"
  import { computed, onMounted } from "vue"
  import Button from "primevue/button"

  import CKEditor from "@ckeditor/ckeditor5-vue"
  import Editor from "./ckeditor"

  const store = useBlogStore()
  const ckeditor = CKEditor.component
  const editor = Editor
  const publishState = computed<boolean>({
    get() {
      return store.blogPost?.status === "Published"
    },
    set(value) {
      store.blogPost = {
        ...store.blogPost!,
        status: value ? "Published" : "Draft",
      }
    },
  })
  onMounted(store.checkBlogPostDetails)
</script>

<style scoped>
  :deep(.ck-editor__editable) {
    --ck-color-base-background: var(--color-background);
    --ck-color-base-foreground: var(--color-foreground);
    --ck-custom-background: var(--color-background);
    --ck-custom-foreground: var(--color-foreground);
  }

  :deep(.ck-content pre) {
    color: var(--color-foreground);
  }
</style>
