<template>
  <div class="flex flex-col w-full overflow-scroll">
    <div class="flex flex-row gap-2 mb-4">
      <Button
        label="New"
        class="h-10"
        icon="pi pi-plus"
        severity="success"
        size="small"
        @click="newSubItem" />
      <Button
        label="Edit"
        class="h-10"
        icon="pi pi-pencil"
        severity="info"
        :disabled="isItemEditDisabled"
        size="small"
        @click="editSubItem" />
      <Button
        label="Delete"
        class="h-10"
        icon="pi pi-trash"
        severity="danger"
        :disabled="isItemDeleteDisabled"
        size="small"
        @click="deleteSubItem" />
    </div>
    <DataTable
      dataKey="id"
      v-if="data !== undefined"
      :value="data.subEmployments"
      v-model:selection="subSelected">
      <Column
        selectionMode="multiple"
        :exportable="false" />
      <Column
        field="employerIcon"
        header="Logo">
        <template #body="{ data }">
          <img
            class="w-8 h-8 rounded-xl"
            :src="data.employerIcon.path" />
        </template>
      </Column>
      <Column
        field="employer"
        header="Employer" />
      <Column
        field="role"
        header="Role" />
      <Column
        field="description"
        header="Description" />
      <Column
        field="location"
        header="Location" />
      <Column
        field="fromDate"
        header="From"
        sortable
        style="min-width: 12rem">
        <template #body="{ data }">{{ data.fromDate.toDateString() }}</template>
      </Column>
      <Column
        field="tillDate"
        header="Till"
        sortable
        style="min-width: 12rem">
        <template #body="{ data }">{{ data.tillDate.toDateString() }}</template>
      </Column>
    </DataTable>
  </div>
</template>

<script setup lang="ts">
  import Button from "primevue/button"
  import Column from "primevue/column"
  import DataTable from "primevue/datatable"

  import { storeToRefs } from "pinia"
  import { useListStore } from "../list/list.store"
  const store = useListStore()
  const { newSubItem, editSubItem, deleteSubItem } = store
  const {
    item: data,
    subSelected,
    isItemEditDisabled,
    isItemDeleteDisabled,
  } = storeToRefs(store)
</script>

<style scoped>
  :deep(.p-datatable .p-datatable-tbody > tr > td) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: auto;
    max-width: 12rem;
  }
</style>
