<template>
  <div class="min-h-screen">
    <div class="text-4xl font-bold p-8 pt-24 text-center">Tech Blog</div>
    <list-tags />
    <div
      class="flex flex-col p-10"
      v-for="blogPost of filteredBlogPosts"
      :key="blogPost.id">
      <list-item
        :post="blogPost"
        @click="goTo(blogPost.id)" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { onMounted } from "vue"
  import { useBlogStore } from "../store"
  import { storeToRefs } from "pinia"
  import listItem from "./list-item.vue"
  import listTags from "./list-tags.vue"
  const store = useBlogStore()
  const { filteredBlogPosts } = storeToRefs(store)
  const { goTo } = store
  onMounted(store.init)
</script>
