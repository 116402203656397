<template>
  <div
    v-if="item !== undefined"
    class="overflow-hidden flex flex-col flex-grow p-6">
    <h1 class="text-2xl mb-6 dark:text-zinc-400">
      {{ isNewItem ? "Create" : "Update" }} project
    </h1>

    <action-buttons />

    <div class="flex flex-wrap gap-8 pt-8 mb-8 overflow-auto">
      <div class="field">
        <span class="p-float-label">
          <InputText
            id="title"
            v-model="item.title" />
          <label for="title">Title</label>
        </span>
      </div>

      <div class="field">
        <span class="p-float-label">
          <InputText
            id="storeURL"
            v-model="item.storeURL.path" />
          <label for="storeURL">Store URL</label>
        </span>
      </div>

      <div class="field">
        <span class="p-float-label">
          <InputText
            id="sourceURL"
            v-model="item.sourceURL.path" />
          <label for="sourceURL">Source URL</label>
        </span>
      </div>

      <div class="field">
        <span class="p-float-label">
          <InputText
            id="owner"
            v-model="item.owner" />
          <label for="owner">Owner</label>
        </span>
      </div>

      <div class="field">
        <span class="p-float-label">
          <Calendar
            id="fromDate"
            v-model="item.date" />
          <label for="fromDate">From date</label>
        </span>
      </div>
    </div>

    <div class="field mb-8">
      <span class="p-float-label">
        <TextArea
          class="w-full h-20"
          id="summary"
          v-model="item.summary" />
        <label for="summary">Summary</label>
      </span>
    </div>

    <div class="field mb-8">
      <span class="p-float-label">
        <TextArea
          class="w-full h-20"
          id="contributions"
          v-model="item.contributions" />
        <label for="contributions">Contributions</label>
      </span>
    </div>

    <div class="field mb-8">
      <span class="p-float-label">
        <TextArea
          class="w-full h-60"
          id="description"
          v-model="item.description" />
        <label for="description">Description</label>
      </span>
    </div>

    <div class="flex flex-col mb-8">
      <div class="mb-2">Logo</div>
      <image-upload
        class="rounded min-w-fit border-black max-w-xs w-32 h-32"
        v-model="item.logo.path" />
    </div>

    <div class="flex flex-col min-h-screen">
      <div class="mb-2">Screenshots</div>
      <image-multi-upload
        class="rounded min-w-fit min-h-fit border-black max-w-xs w-10 h-10"
        v-model="images" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import actionButtons from "./action-buttons.vue"
  import InputText from "primevue/inputtext"
  import TextArea from "primevue/textarea"
  import Calendar from "primevue/calendar"
  import { ImageUpload, ImageMultiUpload } from "@ui/components"
  import { useProjectStore } from "./projects.store"
  import { storeToRefs } from "pinia"
  import { computed } from "vue"
  import { Url } from "@home/domain"

  const store = useProjectStore()
  const { item, isNewItem } = storeToRefs(store)
  const images = computed<string[]>({
    get: () => store.item?.screenshots.map(it => it.path) ?? [],
    set: (value: string[]) => {
      store.item = {
        ...store.item!,
        screenshots: value.map(it => new Url(it)),
      }
    },
  })
</script>
