import { BlogPost } from "@blog/domain"
import { createBlogPostStorage } from "@blog/web-services"
import { defineStore } from "pinia"
import { isEmpty } from "lodash"

interface BlogState {
  blogPosts: BlogPost[]
  selected: BlogPost[]
  isLoading: boolean
  error?: Error

  blogPost?: BlogPost
  isNew: boolean
}

const defaultState: BlogState = {
  blogPosts: [],
  selected: [],
  isLoading: false,
  isNew: true,
  blogPost: undefined,
}

const storage = createBlogPostStorage()

export const useBlogStore = defineStore("blog/web-admin", {
  state: () => defaultState,
  getters: {
    isEditDisabled: state => state.selected.length !== 1,
    isDeleteDisabled: state => state.selected.length < 1,
    getBlogPostById: state => (id: string) =>
      state.blogPosts.find(it => it.id === id),
  },
  actions: {
    async init() {
      await this.fetch()
    },
    async fetch() {
      try {
        this.isLoading = true
        this.blogPosts = (await storage.getAll()) as BlogPost[]
      } catch (error) {
        console.error(error)
      } finally {
        this.isLoading = false
      }
    },
    async goToBlogPost(id: string) {
      this.blogPost = this.getBlogPostById(id)
      if (this.blogPost !== undefined) {
        this.isNew = false
      } else {
        this.isNew = true
        this.blogPost = new BlogPost()
      }
      this.$router.push({ path: `/admin/blog/${id}` })
    },
    async checkBlogPostDetails() {
      if (!this.blogPost) {
        const routeId = this.$router.currentRoute.value.params.id as string
        if (routeId !== "new") {
          await this.fetch()
          this.blogPost = this.getBlogPostById(routeId)
          this.isNew = false
        } else {
          this.isNew = true
          this.blogPost = new BlogPost()
        }
      }
    },
    async newItem() {
      await this.goToBlogPost("new")
    },
    async editItem() {
      const edit = this.selected[0]
      await this.goToBlogPost(edit.id)
    },
    async saveItem() {
      try {
        if (this.isNew && !isEmpty(this.blogPost)) {
          await storage.create(this.blogPost)
        } else if (!this.isNew && !isEmpty(this.blogPost)) {
          await storage.update(this.blogPost)
        }
        await this.cancelItem()
      } catch (error) {
        console.error(error)
      }
    },
    async cancelItem() {
      this.isNew = false
      this.blogPost = undefined
      this.$router.back()
    },
    async deleteItems() {
      const items = this.selected
      const result = await storage.delete(items)
      if (result! instanceof Error) await this.fetch()
    },
  },
})
