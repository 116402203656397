<template>
  <div class="flex flex-col w-full overflow-scroll p-6">
    <h1 class="text-2xl mb-6 dark:text-zinc-400">
      Update technologies section
    </h1>

    <div class="flex flex-row gap-2 mb-6">
      <Button
        label="New"
        class="h-10"
        icon="pi pi-plus"
        severity="success"
        size="small"
        @click="newItem" />
      <Button
        label="Edit"
        class="h-10"
        icon="pi pi-pencil"
        severity="info"
        :disabled="isEditDisabled"
        size="small"
        @click="editItem" />
      <Button
        label="Delete"
        class="h-10"
        icon="pi pi-trash"
        severity="danger"
        :disabled="isDeleteDisabled"
        size="small"
        @click="deleteItem" />
    </div>

    <DataTable
      ref="dt"
      dataKey="id"
      :reorderableColumns="true"
      @rowReorder="(event: any) => rowReorder(event.value)"
      :value="technologies"
      v-model:selection="selected">
      <Column
        rowReorder
        headerStyle="width: 3rem"
        :reorderableColumn="false" />
      <Column
        selectionMode="multiple"
        :exportable="false" />
      <Column
        field="icon"
        header="Logo">
        <template #body="{ data }">
          <img
            class="w-8 h-8 rounded-lg"
            :src="data.icon.path" />
        </template>
      </Column>
      <Column
        field="name"
        header="Name">
      </Column>
      <Column
        field="percentage"
        header="Percentage" />
    </DataTable>
  </div>
</template>

<script setup lang="ts">
  import Button from "primevue/button"
  import DataTable from "primevue/datatable"
  import Column from "primevue/column"
  import { useTechnologiesStore } from "./technologies.store"
  import { storeToRefs } from "pinia"
  import { onMounted } from "vue"
  const store = useTechnologiesStore()
  const { technologies, selected, isEditDisabled, isDeleteDisabled } =
    storeToRefs(store)
  const { rowReorder, newItem, editItem, deleteItem } = store
  onMounted(store.init)
</script>
