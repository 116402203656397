import { defineStore } from "pinia"
import {
  Employment,
  HomePage,
  Introduction,
  Project,
  Technology,
  Url,
} from "@home/domain"
import {
  createIntroductionStorage,
  createEmploymentStorage,
  createProjectStorage,
  createCVDocumentStorage,
  createTechnologiesStorage,
} from "@home/web-services"

interface HomePageState {
  homePage: HomePage
  isLoading: boolean
  error?: Error

  technology?: Technology
  employment?: Employment
  project?: Project
}

const defaultState: HomePageState = {
  homePage: new HomePage(),
  isLoading: true,
  error: undefined,

  technology: undefined,
  employment: undefined,
  project: undefined,
}

const employmentStore = createEmploymentStorage()
const projectStore = createProjectStorage()
const technologiesStore = createTechnologiesStorage()

export const useHomePageStore = defineStore("home/web", {
  state: () => defaultState,
  actions: {
    async init() {
      this.isLoading = true
      await this.fetch()
      this.isLoading = false
    },
    async fetch() {
      const promises = [
        createIntroductionStorage().get(),
        employmentStore.getAll(),
        projectStore.getAll(),
        createCVDocumentStorage().get(),
        technologiesStore.getAll(),
      ]
      try {
        const results = await Promise.all(promises)
        this.homePage = new HomePage(
          results[0] as Introduction,
          (results[1] as Employment[]).sort((a, b) =>
            descending(a.tillDate, b.tillDate),
          ),
          (results[2] as Project[]).sort((a, b) => descending(a.date, b.date)),
          results[3] as Url,
          results[4] as Technology[],
        )
      } catch (error) {
        console.error(error)
      }
    },
    goToTechDetails(technology: Technology) {
      this.technology = technology
      this.$router.push({ path: `/technologies/${technology.id}` })
    },
    async checkTechnologyDetails() {
      if (this.technology === undefined) {
        const routeId = this.$router.currentRoute.value.params.id as string
        this.technology = (await technologiesStore.getById(
          routeId,
        )) as Technology
      }
    },
    goToEmploymentDetails(employment: Employment) {
      this.employment = employment
      this.$router.push({ path: `/employment/${employment.id}` })
    },
    async checkEmploymentDetails() {
      if (this.employment === undefined) {
        const routeId = this.$router.currentRoute.value.params.id as string
        this.employment = (await employmentStore.getById(routeId)) as Employment
      }
    },
    async checkProjectDetails() {
      if (this.project === undefined) {
        const routeId = this.$router.currentRoute.value.params.id as string
        this.project = (await projectStore.getById(routeId)) as Project
      }
    },
    goToProjectDetails(project: Project) {
      this.project = project
      this.$router.push({ path: `/project/${project.id}` })
    },
    back() {
      this.project = undefined
      this.employment = undefined
      this.$router.back()
    },
  },
})

const descending = (a: Date, b: Date) => b.getTime() - a.getTime()
