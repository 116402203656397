<template>
  <div class="rounded-2xl border border-zinc-200 p-6 dark:border-zinc-700/40">
    <div class="flex gap-4 items-center">
      <v-icon
        name="fa-users"
        scale="1.5"
        class="text-zinc-400" />
      <h2 class="text-sm font-semibold text-zinc-900 dark:text-zinc-100">
        Teams
      </h2>
    </div>
    <ol class="mt-6 space-y-4">
      <li
        class="hover:bg-zinc-200/50 dark:hover:bg-zinc-800 rounded-lg -m-2 p-2 cursor-pointer"
        v-for="employment in props.data"
        @click="goToEmploymentDetails(employment)"
        v-bind:key="employment.employer">
        <employment-item :data="employment" />
      </li>
    </ol>
    <a
      class="inline-flex items-center gap-2 justify-center rounded-md py-2 px-3 text-sm outline-offset-2 transition active:transition-none bg-zinc-200/50 font-medium text-zinc-900 hover:bg-zinc-200 active:bg-zinc-100 active:text-zinc-900/60 dark:bg-zinc-800/50 dark:text-zinc-300 dark:hover:bg-zinc-800 dark:hover:text-zinc-50 dark:active:bg-zinc-800/50 dark:active:text-zinc-50/70 mt-6 w-full"
      :href="props.document.path"
      >Download CV
      <download-icon />
    </a>
  </div>
</template>

<script setup lang="ts">
  import { Employment, Url } from "@home/domain"
  import employmentItem from "@/ui/home/employment/employment-item.vue"
  import downloadIcon from "@/assets/icons/download-icon.vue"
  import { useHomePageStore } from "../home.store"
  const props = defineProps<{ data: Employment[]; document: Url }>()
  const store = useHomePageStore()
  const { goToEmploymentDetails } = store
</script>
