<template>
  <div class="min-h-screen flex flex-col gap-6 p-6">
    <back-button />

    <div class="flex justify-center items-center gap-4 mb-12">
      <span class="text-5xl">Technologies stack</span>
    </div>

    <div
      v-for="technology of technologies"
      v-bind:key="technology.id"
      class="flex flex-col gap-4">
      <div class="flex flex-row gap-4 items-center">
        <div class="flex flex-col items-center w-40 gap-2">
          <img
            class="w-10 h-10 rounded-full"
            :src="technology.icon.path" />
          <div class="text-center">{{ technology.name }}</div>
        </div>
        <progress-bar
          class="w-full"
          :value="technology.percentage" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { storeToRefs } from "pinia"
  import backButton from "../components/back-button.vue"
  import { useHomePageStore } from "../home/home.store"
  import ProgressBar from "primevue/progressbar"
  import { computed, onMounted } from "vue"
  import { Technology } from "@home/domain"
  const store = useHomePageStore()
  const { homePage, technology } = storeToRefs(store)
  onMounted(store.checkTechnologyDetails)
  const technologies = computed({
    get: (): Technology[] => {
      const rest =
        homePage.value.tehnologies.filter(
          it => it.id !== technology?.value?.id,
        ) ?? []
      return [
        technology?.value!!,
        ...rest.sort((a, b) => b.percentage - a.percentage),
      ]
    },
    set: () => {},
  })
</script>
