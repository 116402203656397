import { RouteRecordRaw } from "vue-router"
import TechnologiesList from "@/ui/technologies/list.page.vue"
import TechnologiesEdit from "@/ui/technologies/edit.page.vue"

export const technologiesRoutes: RouteRecordRaw[] = [
  {
    path: "",
    component: TechnologiesList,
  },
  {
    path: ":id",
    component: TechnologiesEdit,
  },
]
