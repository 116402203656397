<template>
  <div class="container p-6">
    <h1 class="text-2xl mb-6 dark:text-zinc-400">Upload CV Document</h1>
    <div class="flex flex-wrap mb-6">
      <document-upload v-model="document.path" />
    </div>
    <div class="flex flex-wrap">
      <Button
        label="Upload"
        severity="info"
        @click="upload" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { DocumentUpload } from "@ui/components"
  import Button from "primevue/button"
  import { useCVDocumentStore } from "./upload-cv.store"
  import { storeToRefs } from "pinia"
  import { onMounted } from "vue"
  const store = useCVDocumentStore()
  const { document } = storeToRefs(store)
  const { upload } = store
  onMounted(store.init)
</script>
