<template>
  <div class="flex flex-col gap-6 p-6 overflow-hidden min-h-screen">
    <back-button />

    <div class="flex justify-center items-center gap-6 mb-6">
      <img
        class="rounded-full w-16 h-16"
        :src="employment?.employerIcon.path" />
      <div class="text-5xl">{{ employment?.employer }}</div>
    </div>

    <div class="grid grid-cols-2 gap-6">
      <div class="text-right">Position role</div>
      <div>{{ employment?.role }}</div>
      <div class="text-right">Location</div>
      <div>{{ employment?.location }}</div>
      <div class="text-right">
        {{ dateFormat(employment?.fromDate ?? new Date()) }}
      </div>
      <div>{{ dateFormat(employment?.tillDate ?? new Date()) }}</div>
    </div>

    <div class="flex p-6">
      <div v-html="employment?.description" />
    </div>

    <div
      v-if="!isEmpty(employment?.subEmployments)"
      class="flex p-6">
      <div class="text-xl">Clients</div>
    </div>

    <div
      class="grid grid-cols-2 p-6 gap-4"
      v-for="sub of employment?.subEmployments"
      :key="sub.id">
      <div class="flex items-center gap-4">
        <img
          class="rounded-full w-10 h-10"
          :src="sub.employerIcon.path" />
        <div>{{ sub.employer }}, {{ sub.location }}</div>
        <div>{{ sub.role }}</div>
      </div>
      <div class="flex items-center place-content-end">
        <div>
          {{ dateFormat(sub.fromDate) }} - {{ dateFormat(sub.tillDate) }}
        </div>
      </div>
      <div
        class="col-span-2"
        v-html="sub.description" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import backButton from "@/ui/components/back-button.vue"
  import { useHomePageStore } from "../home/home.store"
  import { isEmpty } from "lodash"
  import { storeToRefs } from "pinia"
  import { onMounted } from "vue"
  const store = useHomePageStore()
  const { employment } = storeToRefs(store)
  onMounted(store.checkEmploymentDetails)
  const dateFormat = (date: Date) =>
    new Intl.DateTimeFormat("en", {
      year: "numeric",
      month: "long",
    }).format(date)
</script>
