<template>
  <div class="flex flex-wrap items-center justify-start gap-4">
    <div
      v-for="item in items"
      :key="item"
      class="relative w-32 h-32 aspect-square rounded-2xl">
      <div class="absolute top-0 right-0 z-10 -m-2">
        <div
          class="rounded-full w-8 h-8 bg-red-600 flex justify-center items-center"
          @click="deleteImage(item)">
          <div class="pi pi-times w-4 h-4 text-white"></div>
        </div>
      </div>
      <div class="absolute v-full h-full">
        <img
          class="v-full h-full rounded"
          :src="item" />
      </div>
    </div>
    <div
      class="relative flex aspect-square border border-zinc-200 dark:border-zinc-400 rounded-xl w-32 h-32">
      <img
        class="z-0 object-cover rounded-lg"
        ref="imagePreview" />
      <div
        class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <photo-camera-icon
          class="dark:fill-zinc-400"
          @click="chooseImage" />
      </div>
      <input
        ref="imagePicker"
        type="file"
        accept="image/png,image/jpeg"
        style="display: none"
        @change="chosenImage" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import photoCameraIcon from "@/assets/icons/photo-camera.vue"
  import { ref } from "vue"

  const props = defineProps<{ modelValue: string[] }>()
  const emits = defineEmits<{
    (e: "update:modelValue", modelValue: string[]): void
  }>()

  const items = ref<string[]>(props.modelValue ?? [])

  const imagePicker = ref<HTMLInputElement>()
  const chooseImage = () => imagePicker.value?.click()
  const chosenImage = async (event: any) => {
    const image = URL.createObjectURL(event.srcElement.files[0])
    items.value = [...items.value, image]
    emits("update:modelValue", items.value)
  }

  const deleteImage = (image: string) => {
    items.value = items.value.filter(it => it !== image)
    if (image.startsWith("blob:")) URL.revokeObjectURL(image)
    emits("update:modelValue", items.value)
  }
</script>
