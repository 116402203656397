import { usePrimeVue } from "primevue/config"

const idTheme = "theme-link"
const lightTheme = "bootstrap4-light-blue"
const darkTheme = "bootstrap4-dark-blue"

export const darkModeSetup = () => {
  const PrimeVue = usePrimeVue()
  const isDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches
  PrimeVue.changeTheme(
    isDarkMode ? lightTheme : darkTheme,
    isDarkMode ? darkTheme : lightTheme,
    idTheme,
    () => {},
  )
  window
    .matchMedia("(prefers-color-scheme: dark)")
    .addEventListener("change", event => {
      const isDarkMode = event.matches
      PrimeVue.changeTheme(
        isDarkMode ? lightTheme : darkTheme,
        isDarkMode ? darkTheme : lightTheme,
        idTheme,
        () => {},
      )
    })
}
