import { Url } from "@home/domain"
import { firestoreApp } from "@config/firebase"
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore"
import { isEmpty } from "lodash"
import { blobUrlToBuffer, isBlobUrl } from "@/blob-to-file"
import { uploadImage } from "@/firebase-file-storage/image-file-storage"
import { v4 } from "uuid"

export interface CVDocumentStore {
  set: (path: Url) => Promise<Url>
  get: () => Promise<Url>
}

export const createCVDocumentStorage = (
  _collection: string = "/home/content",
  _document: string = "/document/link",
): CVDocumentStore => ({
  get: async () => await get(_collection, _document),
  set: async url => await set(url, _collection, _document),
})

const get = async (_collection: string, _document: string): Promise<Url> => {
  const docRef = doc(firestoreApp(), _collection, _document)
  const snapshot = await getDoc(docRef)
  return snapshot.exists() ? (snapshot.data() as Url) : new Url()
}

const set = async (
  url: Url,
  _collection: string,
  _document: string,
): Promise<Url> => {
  const docRef = doc(firestoreApp(), _collection, _document)
  const oldIntroduction = (await getDoc(docRef)).data() as Url
  if (oldIntroduction === undefined) await setDoc(docRef, { ...url })
  const updatedIntroduction = await uploadDocument(url, _collection, _document)
  await updateDoc(docRef, { ...updatedIntroduction })
  return updatedIntroduction
}

const uploadDocument = async (
  url: Url,
  collection_: string,
  document_: string,
): Promise<Url> => {
  const blobs = extractBlobs(url)
  for (const entry of blobs.entries()) {
    const blobUrl = entry[0]
    const filePath = `${collection_}${document_}/${v4() + ".pdf"}`
    const fileBuffer = (await blobUrlToBuffer(blobUrl)) as ArrayBuffer
    const downloadUrl = (await uploadImage(filePath, fileBuffer)) as string
    blobs.set(blobUrl, downloadUrl)
  }
  return replaceBlobs(url, blobs)
}

const extractBlobs = (url: Url): Map<string, string> => {
  const blobMap = new Map<string, string>()
  if (isBlobUrl(url.path)) blobMap.set(url.path, "")
  return blobMap
}

const replaceBlobs = (url: Url, blobs: Map<string, string>): Url => {
  const get = (key: string) =>
    isEmpty(blobs.get(key)) ? key : blobs.get(key) ?? ""
  return {
    path: get(url.path),
  }
}
