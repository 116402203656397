<template>
  <div class="flex gap-4">
    <Button
      class="h-10 w-28"
      label="delete"
      severity="danger"
      @click="deleteItem" />
    <Button
      class="h-10 w-28"
      label="cancel"
      severity="info"
      @click="cancel" />
  </div>
</template>

<script setup lang="ts">
  import Button from "primevue/button"
  import { useLeadsStore } from "./leads.store"
  const store = useLeadsStore()
  const { deleteItem, cancel } = store
</script>
