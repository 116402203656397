import HomePage from "@/ui/home/page.vue"
import TechnologyPage from "@/ui/tech/page.vue"
import EmploymentPage from "@/ui/employment/page.vue"
import ProjectPage from "@/ui/project/page.vue"
import GetInTouchPage from "@/ui/home/get-in-touch/page.vue"
import { RouteRecordRaw } from "vue-router"

export const HomeRoutes: RouteRecordRaw[] = [
  {
    path: "",
    component: HomePage,
  },
  {
    path: "/technologies/:id",
    component: TechnologyPage,
  },
  {
    path: "/employment/:id",
    component: EmploymentPage,
  },
  {
    path: "/project/:id",
    component: ProjectPage,
  },
  {
    path: "/get-in-touch",
    component: GetInTouchPage,
  },
]
