<template>
  <div
    class="relative flex aspect-square border border-zinc-200 dark:border-zinc-400 rounded-xl min-w-20 min-h-20">
    <img
      class="z-0 object-cover rounded-lg"
      ref="imagePreview"
      :src="props.modelValue" />
    <div
      class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <photo-camera-icon
        class="dark:fill-zinc-400"
        @click="chooseImage" />
    </div>
    <input
      ref="imagePicker"
      type="file"
      accept="image/png,image/jpeg"
      style="display: none"
      @change="choosenImage" />
  </div>
</template>

<script setup lang="ts">
  import { ref } from "vue"
  import photoCameraIcon from "@/assets/icons/photo-camera.vue"

  const imagePicker = ref<HTMLInputElement>()
  const imagePreview = ref<HTMLImageElement>()

  const props = defineProps<{ modelValue: string }>()
  const emits = defineEmits<{
    (e: "update:modelValue", modelValue: string): void
  }>()

  const chooseImage = () => imagePicker.value?.click()
  const choosenImage = (event: any) => {
    const selectedImageFile = event.srcElement.files[0]
    imagePreview.value!.src = URL.createObjectURL(selectedImageFile)
    emits("update:modelValue", URL.createObjectURL(selectedImageFile))
  }
</script>
