import {
  DocumentAdminPage,
  EmploymentsAdminPage,
  IntroductionAdminPage,
  LeadsAdminPage,
  ProjectsAdminPage,
  TechnologiesAdminPage,
  employmentsRoutes,
  leadsRoutes,
  projectRoutes,
  technologiesRoutes,
} from "@home/web-admin"
import Welcome from "@/ui/dashboard/welcome.vue"
import { RouteRecordRaw } from "vue-router"
import { BlogAdmin, BlogAdminRoutes } from "@blog/web-admin"

export const adminRoutes: RouteRecordRaw[] = [
  {
    path: "/admin",
    component: Welcome,
  },
  {
    path: "/admin/introduction",
    component: IntroductionAdminPage,
  },
  {
    path: "/admin/employments",
    component: EmploymentsAdminPage,
    children: employmentsRoutes,
  },
  {
    path: "/admin/upload-cv",
    component: DocumentAdminPage,
  },
  {
    path: "/admin/projects",
    component: ProjectsAdminPage,
    children: projectRoutes,
  },
  {
    path: "/admin/technologies",
    component: TechnologiesAdminPage,
    children: technologiesRoutes,
  },
  {
    path: "/admin/leads",
    component: LeadsAdminPage,
    children: leadsRoutes,
  },
  {
    path: "/admin/blog",
    component: BlogAdmin,
    children: BlogAdminRoutes,
  },
]
