import { v4 } from "uuid"

export class HomePage {
  constructor(
    public readonly introduction: Introduction = new Introduction(),
    public readonly employments: Employment[] = [],
    public readonly projects: Project[] = [],
    public readonly cvDocument: Url = new Url(),
    public readonly tehnologies: Technology[] = [],
  ) {}
}

export class Introduction {
  constructor(
    public readonly title: string = "",
    public readonly description: string = "",
    public readonly photo: Url = new Url(),
    public readonly contacts: Contacts = new Contacts(),
  ) {}
}

export class Employment {
  constructor(
    public readonly id: string = v4(),
    public readonly employer: string = "",
    public readonly employerIcon: Url = new Url(),
    public readonly employerWebsite: Url = new Url(),
    public readonly role: string = "",
    public readonly description: string = "",
    public readonly location: string = "",
    public readonly subEmployments: SubEmployment[] = [],
    public readonly fromDate: Date = new Date(),
    public readonly tillDate: Date = new Date(),
  ) {}
}

export class SubEmployment {
  constructor(
    public readonly id: string = v4(),
    public readonly employer: string = "",
    public readonly employerIcon: Url = new Url(),
    public readonly role: string = "",
    public readonly description: string = "",
    public readonly location: string = "",
    public readonly fromDate: Date = new Date(),
    public readonly tillDate: Date = new Date(),
  ) {}
}

export class Project {
  constructor(
    public readonly id: string = v4(),
    public readonly title: string = "",
    public readonly summary: string = "",
    public readonly description: string = "",
    public readonly owner: string = "",
    public readonly contributions: string = "",
    public readonly storeURL: Url = new Url(),
    public readonly sourceURL: Url = new Url(),
    public readonly logo: Url = new Url(),
    public readonly screenshots: Url[] = [],
    public readonly date: Date = new Date(),
  ) {}
}

export class Contacts {
  constructor(
    public readonly email: string = "",
    public readonly telephone: string = "",
    public readonly skype: string = "",
    public readonly github: string = "",
    public readonly linkedin: string = "",
  ) {}
}

export class Url {
  constructor(public readonly path: string = "") {}
}

export class Technology {
  constructor(
    public readonly id: string = v4(),
    public readonly icon: Url = new Url(),
    public readonly name: string = "",
    public readonly percentage: number = 0,
    public readonly order: number = Number.MAX_SAFE_INTEGER,
  ) {}
}

export class Lead {
  constructor(
    public readonly id: string = v4(),
    public readonly name: string = "",
    public readonly email: string = "",
    public readonly date: Date = new Date(),
    public readonly subject: string = "",
    public readonly message: string = "",
  ) {}
}
