<template>
  <div class="min-h-screen p-6">
    <back-button />
    <article
      class="p-12 pt-28"
      v-if="blogPostDetails">
      <header class="flex flex-col">
        <h1
          class="mt-6 text-4xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-5xl mb-8 text-center">
          {{ blogPostDetails.title }}
        </h1>
        <time
          datetime="2022-09-05"
          class="mt-2 flex items-center text-base text-zinc-400 dark:text-zinc-500"
          ><span
            class="h-4 w-0.5 rounded-full bg-zinc-200 dark:bg-zinc-500"></span
          ><span class="ml-3">{{
            blogPostDetails.publishedAt.toDateString()
          }}</span></time
        >
      </header>

      <div
        class="mt-8 ck-content"
        v-code-highlight
        v-html="blogPostDetails.content" />
    </article>
  </div>
</template>

<script setup lang="ts">
  import backButton from "@/ui/components/back-button.vue"
  import { onMounted } from "vue"
  import { useBlogStore } from "../store"
  import { storeToRefs } from "pinia"
  import hljs from "highlight.js"
  import "highlight.js/styles/panda-syntax-dark.css"

  const store = useBlogStore()
  const { blogPostDetails } = storeToRefs(store)
  onMounted(store.checkBlogPostDetails)

  const vCodeHighlight = {
    mounted: (el: HTMLElement) => {
      el.querySelectorAll("code").forEach(hljs.highlightElement)
    },
  }
</script>

<style scoped>
  :deep(.ck-content pre) {
    background: black;
    padding: 16px;
    border: 2cap;
    border-radius: 8px;
  }
</style>
