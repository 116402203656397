<template>
  <Loading
    :data="isLoading"
    :error="error">
    <edit-introduction />
  </Loading>
</template>

<script setup lang="ts">
  import editIntroduction from "@/ui/introduction/introduction.vue"
  import { Loading } from "@ui/components"
  import { useIntroductionStore } from "./introduction.store"
  import { onMounted } from "vue"
  import { storeToRefs } from "pinia"
  const store = useIntroductionStore()
  const { isLoading, error } = storeToRefs(store)
  onMounted(store.init)
</script>
