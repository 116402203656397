import { errorToast, successToast } from "@/shared/toast-message"
import { Lead } from "@home/domain"
import { createLeadsStorage } from "@home/web-services"
import { defineStore } from "pinia"

interface LeadsState {
  leads: Lead[]
  selected: Lead[]
  error?: Error
}

const defaultState: LeadsState = {
  leads: [],
  selected: [],
  error: undefined,
}

const storage = createLeadsStorage()

export const useLeadsStore = defineStore("home/web-admin/leads", {
  state: (): LeadsState => defaultState,
  getters: {
    isCheckDisabled: state => state.selected.length !== 1,
    isDeleteDisabled: state => state.selected.length === 0,
    detail: state => state.selected[0],
  },
  actions: {
    async init() {
      await this.fetch()
    },
    async fetch() {
      try {
        this.leads = (await storage.getAll()) as Lead[]
      } catch (error) {
        console.error(error)
      }
    },
    async details() {
      const lead = this.selected[0]
      this.$router.push({ path: `/admin/leads/${lead.id}` })
    },
    async cancel() {
      this.$router.back()
      this.selected = []
    },
    async deleteItem() {
      await this.delete(this.selected)
      await this.fetch()
      this.selected = []
    },
    async delete(leads: Lead[]) {
      try {
        await storage.delete(leads)
        this.leads = [...this.leads.filter(it => !this.leads.includes(it))]
        this.$toast.add(successToast())
      } catch (error) {
        this.$toast.add(errorToast(error.message))
        console.error(error)
      }
    },
  },
})
