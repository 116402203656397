<template>
  <div class="flex flex-col gap-6 p-6">
    <back-button />

    <div class="flex justify-center items-center gap-4">
      <img
        class="rounded-full -p-4 w-20 h-20"
        :src="project?.logo.path" />
      <span class="text-5xl">{{ project?.title }}</span>
    </div>

    <div class="flex justify-center items-center">
      <span class="text-2xl">{{ project?.owner }}</span>
    </div>

    <div class="flex justify-center">
      <span
        class="text-xl"
        v-html="project?.contributions" />
    </div>

    <div class="flex justify-center">
      <span
        class="text-xl"
        v-html="project?.description" />
    </div>

    <div
      v-if="
        !isEmpty(project?.storeURL.path) || !isEmpty(project?.sourceURL.path)
      "
      class="flex justify-center items-center gap-8">
      <a
        v-if="!isEmpty(project?.storeURL.path)"
        :href="project?.storeURL.path">
        <v-icon
          class="w-10 h-10"
          name="fa-google-play" />
      </a>
      <a
        v-if="!isEmpty(project?.sourceURL.path)"
        :href="project?.sourceURL.path">
        <v-icon
          class="w-10 h-10"
          name="fa-github" />
      </a>
    </div>

    <div class="flex place-items-center justify-center">
      <div
        class="min-[320px]:w-3/4 min-[320px]:h-3/4 sm:w-2/3 sm:h-2/3 md:w-1/2 md:h-1/2 lg:w-1/3 lg:h-1/3 xl:w-1/4 xl:h-1/4">
        <Galleria
          :value="project?.screenshots"
          :numVisible="8"
          :circular="true"
          :showItemNavigators="true"
          :showThumbnailNavigators="false">
          <template #item="slotProps">
            <img
              class="object-contain w-full h-full"
              :src="slotProps.item.path" />
          </template>
          <template #thumbnail="slotProps">
            <img
              class="aspect-square object-fit w-20 h-20"
              :src="slotProps.item.path" />
          </template>
        </Galleria>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import Galleria from "primevue/galleria"
  import backButton from "@/ui/components/back-button.vue"
  import { useHomePageStore } from "../home/home.store"
  import { isEmpty } from "lodash"
  import { storeToRefs } from "pinia"
  import { onMounted } from "vue"
  const state = useHomePageStore()
  const { project } = storeToRefs(state)
  onMounted(state.checkProjectDetails)
</script>
