<template>
  <div
    class="flex flex-col gap-4 rounded-2xl border border-zinc-200 p-6 dark:border-zinc-700/40">
    <div class="flex gap-4 items-center mb-4">
      <v-icon
        name="fa-mail-bulk"
        scale="1.5"
        class="text-zinc-400" />
      <h2 class="text-sm font-semibold text-zinc-900 dark:text-zinc-100">
        Contact Me
      </h2>
    </div>
    <div class="flex flex-wrap gap-4">
      <div class="flex-1 flex flex-col min-w-100">
        <h1 class="mb-1 font-semibold dark:text-zinc-400 text-sm">E-Mail *</h1>
        <input
          class="rounded p-2 border border-zinc-400/50"
          type="text"
          v-model="lead.email" />
        <div
          v-if="isInvalidEmail"
          class="text-red-700 text-sm mt-1">
          Hmmm..., this doesn't look good
        </div>
      </div>
      <div class="flex-1 flex flex-col min-w-100">
        <h1 class="mb-1 font-semibold dark:text-zinc-400 text-sm">Name</h1>
        <input
          class="rounded p-2 flex-1 border border-zinc-400/50"
          text="text"
          v-model="lead.name" />
      </div>
    </div>
    <div>
      <h1 class="mb-1 font-semibold dark:text-zinc-400 text-sm">Subject *</h1>
      <input
        class="rounded p-2 w-[100%] border border-zinc-400/50"
        type="text"
        v-model="lead.subject" />
    </div>
    <div>
      <h1 class="mb-1 font-semibold dark:text-zinc-400 text-sm">Message *</h1>
      <textarea
        class="rounded p-2 w-[100%] h-40 border border-zinc-400/50"
        type="text"
        v-model="lead.message" />
    </div>
    <div class="flex flex-row-reverse">
      <button
        class="inline-flex items-center gap-2 justify-center rounded-md py-2 px-3 text-sm outline-offset-2 transition active:transition-none bg-zinc-200 text-zinc-900 hover:bg-zinc-400/50 active:bg-zinc-00 active:text-zinc-900/60 dark:bg-zinc-800/50 dark:text-zinc-300 dark:hover:bg-zinc-800 dark:hover:text-zinc-50 dark:active:bg-zinc-800/50 dark:active:text-zinc-50/70 mt-2 w-[35%] font-extrabold"
        type="submit"
        @click="send">
        Send
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useGetInTouchStore } from "./get-in-touch.store"
  import { storeToRefs } from "pinia"
  import { watch } from "vue"
  const store = useGetInTouchStore()
  const { lead, isInvalidEmail } = storeToRefs(store)
  const { send, resetInvalidEmail } = store
  watch(
    () => lead.value.email,
    (_, _1) => resetInvalidEmail(),
  )
</script>
