<template>
  <div class="flex flex-row flex-wrap gap-2">
    <div class="grow flex flex-row gap-2">
      <div
        class="flex h-10 w-10 items-center justify-center rounded-full shadow-md shadow-zinc-800/5 ring-1 ring-zinc-900/5 dark:border dark:border-zinc-700/50 dark:bg-zinc-800 dark:ring-0">
        <img
          alt=""
          loading="lazy"
          decoding="async"
          data-nimg="1"
          class="h-9 w-9 rounded-full"
          style="color: transparent"
          :src="data.employerIcon.path"
          width="32"
          height="32" />
      </div>
      <div class="flex flex-col">
        <div class="text-sm font-medium text-zinc-900 dark:text-zinc-100">
          {{ props.data.employer }}
        </div>
        <div class="text-xs text-zinc-500 dark:text-zinc-400">
          {{ props.data.role }}
        </div>
      </div>
    </div>
    <div class="flex">
      <div
        class="pl-12 text-xs text-zinc-400 dark:text-zinc-500 mb-1 place-self-end">
        {{ dateFormat(props.data.fromDate) }} -
        {{ dateFormat(props.data.tillDate) }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { Employment } from "@home/domain"
  const props = defineProps<{ data: Employment }>()

  const dateFormat = (date: Date) =>
    new Intl.DateTimeFormat("en", {
      year: "numeric",
      month: "short",
    }).format(date)
</script>
