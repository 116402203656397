export const blobUrlToFile = async (
  blobUrl: string,
): Promise<File | undefined> => {
  try {
    const response = await fetch(blobUrl)
    const blob = await response.blob()

    // Extract the filename from the blob URL
    const urlParts = blobUrl.split("/")
    const filename = urlParts[urlParts.length - 1]

    // Determine the file type based on the blob's content
    const contentType = blob.type || "application/octet-stream"

    // Create a new File object with the blob data, filename, and content type
    const file = new File([blob], filename, { type: contentType })

    return file
  } catch (error) {
    console.error("Error extracting file:", error)
    return undefined
  }
}

export const blobUrlToBuffer = async (
  blobUrl: string,
): Promise<ArrayBuffer | undefined> => {
  try {
    const response = await fetch(blobUrl)
    return await response.arrayBuffer()
  } catch (error) {
    console.error("Error extracting file:", error)
    return undefined
  }
}

export const isBlobUrl = (url: string) => url.match("blob:*")
