import { Employment, SubEmployment } from "@home/domain"
import { defineStore } from "pinia"
import { createEmploymentStorage } from "@home/web-services"
import { isEmpty } from "lodash"
import { successToast, errorToast } from "@/shared/toast-message"

const storage = createEmploymentStorage()

interface EmploymentsState {
  employments: Employment[]
  selected: Employment[]
  expanded: Employment[]

  item?: Employment
  subSelected: SubEmployment[]
  isItemNew: boolean

  subItem?: SubEmployment
  isSubItemNew: boolean

  error?: Error
}

const defaultState: EmploymentsState = {
  employments: [],
  selected: [],
  expanded: [],

  item: undefined,
  subSelected: [],
  isItemNew: true,

  subItem: undefined,
  isSubItemNew: true,

  error: undefined,
}

export const useListStore = defineStore("home/web-admin/employments", {
  state: (): EmploymentsState => defaultState,
  getters: {
    isEditDisabled: state => state.selected.length !== 1,
    isDeleteDiasabled: state => state.selected.length === 0,
    isItemEditDisabled: state => state.subSelected.length !== 1,
    isItemDeleteDisabled: state => state.subSelected.length === 0,
  },
  actions: {
    async init() {
      await this.fetch()
    },

    async fetch() {
      const result = await storage.getAll()
      this.employments = Array.isArray(result) ? (result as Employment[]) : []
      this.error = result instanceof Error ? result : undefined
    },

    async newItem() {
      this.isItemNew = true
      this.item = new Employment()
      this.selected = []
      this.$router.push({ path: `/admin/employments/${this.item.id}` })
    },

    async editItem() {
      this.isItemNew = false
      this.item = this.selected[0]
      this.selected = []
      this.$router.push({ path: `/admin/employments/${this.item.id}` })
    },

    async saveItem() {
      if (this.isItemNew && !isEmpty(this.item)) {
        const item = (await storage.create(this.item)) as Employment
        this.employments = [
          ...this.employments.filter(it => it.id !== item.id),
          item,
        ]
      } else if (!this.isItemNew && !isEmpty(this.item)) {
        const item = (await storage.update(this.item)) as Employment
        this.employments = [
          ...this.employments.filter(it => it.id !== item.id),
          item,
        ]
      }
      this.cancelItem()
    },

    async deleteItems() {
      await this.delete()
    },

    async cancelItem() {
      this.$router.back()
      this.isItemNew = false
      this.item = undefined
      this.subSelected = []
    },

    async newSubItem() {
      this.isSubItemNew = true
      this.subItem = new SubEmployment()
      this.subSelected = []
      this.$router.push({
        path: `/admin/employments/${this.item!!.id}/sub/${this.subItem.id}`,
      })
    },

    async editSubItem() {
      this.isSubItemNew = false
      this.subItem = this.subSelected[0]
      this.subSelected = []
      this.$router.push({
        path: `/admin/employments/${this.item!!.id}/sub/${this.subItem.id}`,
      })
    },

    async deleteSubItem() {
      const subs: SubEmployment[] =
        this.item?.subEmployments.filter(
          it => !this.subSelected.includes(it),
        ) ?? []
      this.item = { ...this.item!, subEmployments: subs }
    },

    async saveSubItem() {
      const subs: SubEmployment[] = [
        ...this.item!.subEmployments.filter(it => it.id !== this.item!.id),
        this.subItem!,
      ]
      this.item = { ...this.item!, subEmployments: subs }
      this.cancelSubItem()
    },

    async cancelSubItem() {
      this.$router.back()
      this.isSubItemNew = true
      this.subItem = undefined
    },

    async create(employment: Employment) {
      try {
        ;(await storage.create(employment)) as Employment
        await this.fetch()
        this.selected = []
        this.$toast.add(successToast())
      } catch (error) {
        this.$toast.add(errorToast(error))
        console.error(error)
      }
    },

    async update(employment: Employment) {
      try {
        await storage.update(employment)
        await this.fetch()
        this.selected = []
        this.$toast.add(successToast())
      } catch (error) {
        this.$toast.add(errorToast(error))
        console.error(error)
      }
    },

    async delete() {
      try {
        await storage.delete(this.selected)
        await this.fetch()
        this.selected = []
        this.$toast.add(successToast())
      } catch (error) {
        this.$toast.add(errorToast(error))
        console.error(error)
      }
    },
  },
})
