<template>
  <div class="flex justify-center h-screen">
    <div class="flex flex-col gap-8">
      <div
        class="text-center mb-8 mt-16 text-900 text-3xl font-medium dark:text-zinc-400">
        Sign in to admin dashboard!
      </div>

      <div class="field">
        <span class="p-float-label">
          <InputText
            class="w-full"
            id="username"
            v-model="username" />
          <label for="username">Username</label>
        </span>
      </div>

      <div class="field">
        <span class="p-float-label">
          <InputText
            type="password"
            class="w-full"
            id="password"
            v-model="password" />
          <label for="password">Password</label>
        </span>
      </div>

      <div class="flex align-items-start dark:text-zinc-400 items-center">
        <Checkbox
          id="rememberme"
          :binary="true"
          v-model="rememberMe"
          class="mr-2 dark:bg-zinc-900"></Checkbox>
        <label for="rememberme">Remember me</label>
      </div>

      <div v-if="!isLoading">
        <Button
          label="Sign In"
          icon="pi pi-user"
          severity="info"
          @click="login"
          class="w-full"></Button>
      </div>
      <div
        v-else
        class="flex w-full">
        <ProgressSpinner stroke-width="2" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import Button from "primevue/button"
  import Checkbox from "primevue/checkbox"
  import InputText from "primevue/inputtext"
  import ProgressSpinner from "primevue/progressspinner"
  import { useLoginStore } from "./login.store"
  import { storeToRefs } from "pinia"
  const store = useLoginStore()
  const { username, password, rememberMe, isLoading } = storeToRefs(store)
  const { login } = store
</script>
