<template>
  <div class="flex flex-col w-full overflow-scroll p-6">
    <h1 class="text-2xl mb-6 dark:text-zinc-400">Update employment section</h1>

    <div class="flex flex-row gap-2 mb-6">
      <Button
        label="New"
        class="h-10"
        icon="pi pi-plus"
        severity="success"
        size="small"
        @click="newItem" />
      <Button
        label="Edit"
        class="h-10"
        icon="pi pi-pencil"
        severity="info"
        :disabled="isEditDisabled"
        size="small"
        @click="editItem" />
      <Button
        label="Delete"
        class="h-10"
        icon="pi pi-trash"
        severity="danger"
        :disabled="isDeleteDiasabled"
        size="small"
        @click="deleteItems" />
    </div>

    <DataTable
      ref="dt"
      dataKey="id"
      :value="employments"
      v-model:selection="selected"
      v-model:expanded-rows="expanded">
      <Column
        selectionMode="multiple"
        :exportable="false" />
      <Column expander />
      <Column
        field="employerIcon"
        header="Logo">
        <template #body="{ data }">
          <img
            class="w-8 h-8 rounded-lg"
            :src="data.employerIcon.path" />
        </template>
      </Column>
      <Column
        field="employer"
        header="Employer">
      </Column>
      <Column
        field="role"
        header="Role" />
      <Column
        field="description"
        header="Description" />
      <Column
        field="location"
        header="Location">
      </Column>
      <Column
        field="fromDate"
        header="From"
        sortable>
        <template #body="{ data }">{{ data.fromDate.toDateString() }}</template>
      </Column>
      <Column
        field="tillDate"
        header="Till"
        sortable>
        <template #body="{ data }">{{ data.tillDate.toDateString() }}</template>
      </Column>
      <template #expansion="{ data }">
        <sub-list :employment="data" />
      </template>
    </DataTable>
  </div>
</template>

<script setup lang="ts">
  import Button from "primevue/button"
  import Column from "primevue/column"
  import DataTable from "primevue/datatable"
  import subList from "./sub-list.vue"
  import { onMounted } from "vue"
  import { useListStore } from "./list.store"
  import { storeToRefs } from "pinia"
  const store = useListStore()
  const { employments, selected, expanded, isEditDisabled, isDeleteDiasabled } =
    storeToRefs(store)
  const { newItem, editItem, deleteItems } = store
  onMounted(store.init)
</script>

<style scoped>
  :deep(.p-datatable .p-datatable-tbody > tr > td) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: auto;
    max-width: 12rem;
  }
  :deep(.p-datatable .p-datatable-tbody > .p-datatable-row-expansion > td) {
    padding: 0px;
    margin: 0px;
  }
</style>
