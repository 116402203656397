<template>
  <div
    class="flex flex-wrap items-center justify-start gap-4 border border-zinc-200 dark:border-zinc-400 p-2 rounded-xl">
    <a
      v-if="isHttp(props.modelValue)"
      :href="props.modelValue"
      >CV Document
    </a>
    <a
      v-else-if="isBlob(props.modelValue)"
      :href="props.modelValue">
      Loaded, hit upload button
    </a>
    <div v-else>No document</div>
    <Button
      label="Browse PDF"
      severity="info"
      @click="chooseFile" />
    <input
      ref="filePicker"
      type="file"
      accept="application/pdf"
      style="display: none"
      @change="chosenFile" />
  </div>
</template>

<script setup lang="ts">
  import Button from "primevue/button"
  import { ref } from "vue"

  const props = defineProps<{ modelValue: string }>()
  const emits = defineEmits<{
    (e: "update:modelValue", modelValue: string): void
  }>()

  const filePicker = ref<HTMLInputElement>()
  const filePicked = ref<File>()

  const chooseFile = () => filePicker.value?.click()
  const chosenFile = (event: any) => {
    const selectedDocumentFile = event.srcElement.files[0] as File
    filePicked.value = event.srcElement.files[0] as File
    emits("update:modelValue", URL.createObjectURL(selectedDocumentFile))
  }

  const isHttp = (url: string) => url.startsWith("http")
  const isBlob = (url: string) => url.startsWith("blob")
</script>
