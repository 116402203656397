import { RouteRecordRaw } from "vue-router"
import List from "@/ui/employments/list/list.page.vue"
import ListItem from "@/ui/employments/item/list-item.page.vue"
import ListItemSub from "@/ui/employments/item-sub/list-item-sub.page.vue"

export const employmentsRoutes: RouteRecordRaw[] = [
  {
    path: "",
    component: List,
  },
  {
    path: ":id",
    component: ListItem,
  },
  {
    path: ":id/sub/:subId",
    component: ListItemSub,
  },
]
