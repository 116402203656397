import { BlogPost } from "@blog/domain"
import { v4, validate } from "uuid"
import {
  collection,
  updateDoc,
  query,
  getDocs,
  doc,
  deleteDoc,
  setDoc,
  getDoc,
  Timestamp,
} from "firebase/firestore"
import { firestoreApp } from "@config/firebase"

interface BlogPostStorage {
  getAll: () => Promise<BlogPost[] | Error>
  getById: (id: string) => Promise<BlogPost | undefined | Error>
  create: (post: BlogPost) => Promise<BlogPost | Error>
  update: (post: BlogPost) => Promise<BlogPost | Error>
  delete: (posts: BlogPost[]) => Promise<void | Error>
}

export const createBlogPostStorage = (
  _collection = "/blog/content",
  _document = "/blog-posts",
): BlogPostStorage => ({
  getAll: async () => await getAll(_collection, _document),
  getById: async (id: string) => await getById(id, _collection, _document),
  create: async (post: BlogPost) => await create(post, _collection, _document),
  update: async (post: BlogPost) => await update(post, _collection, _document),
  delete: async (posts: BlogPost[]) =>
    await deletes(posts, _collection, _document),
})

const getAll = async (
  _collection: string,
  _document: string,
): Promise<BlogPost[] | Error> => {
  const queryRef = query(collection(firestoreApp(), _collection, _document))
  try {
    const docSnapshot = await getDocs(queryRef)
    return docSnapshot.docs.map(doc => doc.data()).map(fromFirebase)
  } catch (error) {
    console.error(error)
    return Error(error.message)
  }
}

const getById = async (
  id: string,
  _collection: string,
  _document: string,
): Promise<BlogPost | undefined | Error> => {
  const docRef = doc(firestoreApp(), _collection, _document, id)
  try {
    const doc = await getDoc(docRef)
    return fromFirebase(doc.data())
  } catch (error) {
    console.error(error)
    return Error(error.message)
  }
}

const create = async (
  post: BlogPost,
  collection_: string,
  document_: string,
): Promise<BlogPost | Error> => {
  const collRef = collection(firestoreApp(), collection_, document_)
  try {
    const document = toFirebase(post)
    const docRef = doc(collRef, document.id)
    await setDoc(docRef, document)
    return fromFirebase(document)
  } catch (error) {
    console.error(error)
    return Error(error)
  }
}

const update = async (
  post: BlogPost,
  _collection: string,
  _document: string,
): Promise<BlogPost | Error> => {
  const docRef = doc(firestoreApp(), _collection, _document, post.id)
  try {
    const doc = toFirebase(post)
    await updateDoc(docRef, doc)
    const from = fromFirebase(doc)
    return from
  } catch (error) {
    return Error(error)
  }
}

const deletes = async (
  posts: BlogPost[],
  _collection: string,
  _document: string,
): Promise<void | Error> => {
  for (const post of posts) {
    const docRef = doc(firestoreApp(), _collection, _document, post.id)
    try {
      await deleteDoc(docRef)
    } catch (error) {
      console.error(error)
      return Error(error)
    }
  }
  return
}

const toFirebase = (post: BlogPost) => {
  const { id, publishedAt, ...rest } = post
  const validatedId = validate(id) ? id : v4()
  return {
    id: validatedId,
    publishedAt: Timestamp.fromDate(publishedAt),
    ...rest,
  }
}

const fromFirebase = (post: any): BlogPost => {
  const { publishedAt, ...rest } = post
  return {
    publishedAt: publishedAt.toDate() ?? new Date(),
    ...rest,
  }
}
