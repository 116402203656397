import { createApp, markRaw } from "vue"
import App from "./app.vue"
import { router } from "./router/router"
import { createPinia } from "pinia"
import { initializeFirebase } from "@config/firebase"
import * as FaIcons from "oh-vue-icons/icons/fa"
import { OhVueIcon, addIcons } from "oh-vue-icons"
import PrimeVue from "primevue/config"
import ToastService from "primevue/toastservice"
import Toast from "primevue/toast"
import { useToast } from "primevue/usetoast"

// Tailwind css
import "./assets/styles/main.css"

// Prime vue css
//import "primevue/resources/themes/viva-dark/theme.css" - see index.html

// Primeflex
// import 'primeflex/primeflex.css'

// Prime Icons
import "primeicons/primeicons.css"

// Icons
// https://oh-vue-icons.netlify.app/ - Font Awesome
addIcons(...Object.values({ ...FaIcons }))

// App
createApp(App)
  .component("v-icon", OhVueIcon)
  .component("Toast", Toast)
  .use(PrimeVue)
  .use(ToastService)
  .use(router)
  .use(
    createPinia().use(({ store }) => {
      store.$router = markRaw(router)
      store.$toast = markRaw(useToast())
    }),
  )
  .mount("#app")

// Firebase
initializeFirebase()
