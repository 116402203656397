<template>
  <div
    class="rounded-full border-2 dark:text-zinc-200 w-10 h-10 place-items-center flex justify-center cursor-pointer"
    @click="back">
    <v-icon name="fa-arrow-left" />
  </div>
</template>

<script setup lang="ts">
  import { useBlogStore } from "../store"
  const store = useBlogStore()
  const { back } = store
</script>
