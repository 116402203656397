<template>
  <div class="overflow-hidden flex flex-col p-6">
    <div class="text-2xl dark:text-zinc-400 mb-6">
      {{ isItemNew ? "Create" : "Update" }} employment
    </div>
    <list-item-buttons />
    <list-item />
    <list-item-table />
  </div>
</template>

<script setup lang="ts">
  import listItemButtons from "./list-item-buttons.vue"
  import listItem from "./list-item.vue"
  import listItemTable from "./list-item-table.vue"
  import { storeToRefs } from "pinia"
  import { useListStore } from "../list/list.store"
  const store = useListStore()
  const { isItemNew } = storeToRefs(store)
</script>
