import { errorToast, successToast } from "@/shared/toast-message"
import { Url } from "@home/domain"
import { createCVDocumentStorage } from "@home/web-services"
import { defineStore } from "pinia"

interface CVDocumentState {
  document: Url
}

const defaultState: CVDocumentState = {
  document: { path: "" },
}

const storage = createCVDocumentStorage()

export const useCVDocumentStore = defineStore("home/web-admin/document", {
  state: () => defaultState,
  actions: {
    async init() {
      const document = await storage.get()
      this.document = document
    },

    async upload() {
      try {
        const document = await storage.set(this.document)
        this.document = document
        this.$toast.add(successToast())
      } catch (error) {
        this.$toast.add(errorToast())
        console.error(error)
      }
    },
  },
})
