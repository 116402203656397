import { Lead } from "@home/domain"
import { createLeadsStorage } from "@home/web-services"
import { defineStore } from "pinia"

interface GetInTouchState {
  lead: Lead
  isInvalidEmail: boolean
}

const defaultState: GetInTouchState = {
  lead: new Lead(),
  isInvalidEmail: false,
}

const storage = createLeadsStorage()

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

export const useGetInTouchStore = defineStore("home/web/get-in-touch", {
  state: () => defaultState,
  actions: {
    async send() {
      if (!emailRegex.test(this.lead.email)) {
        this.isInvalidEmail = true
        return
      }

      const result = await storage.create(this.lead)
      if (result instanceof Error) {
        this.$toast.add({
          severity: "error",
          summary: "🙁",
          detail: "Ops something went wrong!",
          life: 6000,
        })
      } else {
        this.$toast.add({
          severity: "success",
          summary: "😊",
          detail: "Thank you! I'll come back to you soon!",
          life: 6000,
        })
      }
    },
    resetInvalidEmail() {
      this.isInvalidEmail = false
    },
  },
})
