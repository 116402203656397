<template>
  <div
    id="about"
    class="p-12 pt-24">
    <Loading
      :data="data"
      :error="error">
      <introduction
        class="mb-12"
        :data="data.introduction" />
      <technology-list
        id="technologies"
        class="mb-12"
        :data="data.tehnologies" />
      <employement-list
        id="teams"
        :data="data.employments"
        :document="data.cvDocument" />
      <div
        id="projects"
        class="mb-12" />
      <projects-list
        class="mb-12"
        :data="data.projects" />
      <get-in-touch-form id="contact" />
    </Loading>
  </div>
</template>

<script setup lang="ts">
  import { Loading } from "@ui/components"
  import introduction from "@/ui/home/introduction/introduction.vue"
  import technologyList from "@/ui/home/technology/technology-list.vue"
  import employementList from "@/ui/home/employment/employment-list.vue"
  import projectsList from "@/ui/home/projects/project-list.vue"
  import getInTouchForm from "@/ui/home/get-in-touch/get-in-touch-form.vue"
  import { useHomePageStore } from "./home.store"
  import { onMounted } from "vue"
  import { storeToRefs } from "pinia"
  const store = useHomePageStore()
  const { homePage: data, error } = storeToRefs(store)
  onMounted(store.init)
</script>
