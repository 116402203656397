import { Project, Url } from "@home/domain"
import { firestoreApp } from "@config/firebase"
import {
  collection,
  updateDoc,
  query,
  getDocs,
  doc,
  deleteDoc,
  setDoc,
  getDoc,
  Timestamp,
} from "firebase/firestore"
import { v4, validate } from "uuid"
import { blobUrlToBuffer, isBlobUrl } from "@/blob-to-file"
import {
  deleteStored,
  uploadImage,
} from "@/firebase-file-storage/image-file-storage"
import { isEmpty } from "lodash"

export interface ProjectStorage {
  create: (project: Project) => Promise<Project | Error>
  get: (project: Project) => Promise<Project | Error>
  getById: (id: string) => Promise<Project | Error>
  getAll: () => Promise<Project[] | Error>
  update: (project: Project) => Promise<Project | Error>
  delete: (project: Project[]) => Promise<void | Error>
}

export const createProjectStorage = (
  _collection = "/home/content",
  _document = "/projects",
): ProjectStorage => ({
  create: async (project: Project) =>
    await create(project, _collection, _document),
  get: async (project: Project) => await get(project, _collection, _document),
  getById: async (id: string) => await getById(id, _collection, _document),
  getAll: async () => await getAll(_collection, _document),
  update: async (project: Project) =>
    await update(project, _collection, _document),
  delete: async (project: Project[]) =>
    await deletes(project, _collection, _document),
})

const create = async (
  project: Project,
  collection_: string,
  document_: string,
): Promise<Project | Error> => {
  const collRef = collection(firestoreApp(), collection_, document_)
  try {
    const newProject = await uploadImages(project, collection_, document_)
    const document = toFirebase(newProject)
    const docRef = doc(collRef, document.id)
    await setDoc(docRef, document)
    return fromFirebase(document)
  } catch (error) {
    console.error(error)
    return Error(error)
  }
}

const get = async (
  project: Project,
  collection_: string,
  document_: string,
): Promise<Project | Error> => {
  const docRef = doc(firestoreApp(), collection_, document_, project.id)
  try {
    const doc = await getDoc(docRef)
    return fromFirebase(doc.data())
  } catch (error) {
    console.error(error)
    return Error(error.message)
  }
}

const getById = async (
  id: string,
  collection_: string,
  document_: string,
): Promise<Project | Error> => {
  const docRef = doc(firestoreApp(), collection_, document_, id)
  try {
    const doc = await getDoc(docRef)
    return fromFirebase(doc.data())
  } catch (error) {
    console.error(error)
    return Error(error.message)
  }
}

const getAll = async (
  collection_: string,
  document_: string,
): Promise<Project[] | Error> => {
  const queryRef = query(collection(firestoreApp(), collection_, document_))
  try {
    const docSnapshot = await getDocs(queryRef)
    return docSnapshot.docs.map(doc => doc.data()).map(fromFirebase)
  } catch (error) {
    console.error(error)
    return Error(error.message)
  }
}

const update = async (
  project: Project,
  collection_: string,
  document_: string,
): Promise<Project | Error> => {
  const docRef = doc(firestoreApp(), collection_, document_, project.id)
  try {
    const dbProject = fromFirebase((await getDoc(docRef)).data())
    const dbScreenshots = dbProject.screenshots.map(it => it.path)
    const deleteScreenshots = dbScreenshots
      .filter(it => !project.screenshots.map(it => it.path).includes(it))
      .filter(it => !isBlobUrl(it))
    try {
      await Promise.all(
        deleteScreenshots.map(async it => await deleteStored(it)),
      )
    } catch (error) {
      console.error(error)
    }
    const updatedProject = await uploadImages(project, collection_, document_)
    const doc = toFirebase(updatedProject)
    await updateDoc(docRef, doc)
    const from = fromFirebase(doc)
    return from
  } catch (error) {
    return Error(error)
  }
}

const deletes = async (
  projects: Project[],
  collection_: string,
  document_: string,
): Promise<void | Error> => {
  for (const project of projects) {
    const docRef = doc(firestoreApp(), collection_, document_, project.id)
    try {
      const project_ = fromFirebase((await getDoc(docRef)).data())
      try {
        await Promise.all(
          project_.screenshots.map(async it => await deleteStored(it.path)),
        )
      } catch (error) {
        console.error(error)
      }
      await deleteDoc(docRef)
    } catch (error) {
      console.error(error)
      return Error(error)
    }
  }
  return
}

const toFirebase = (project: Project) => {
  const { id, storeURL, sourceURL, logo, screenshots, date, ...rest } = project
  const validatedId = validate(id) ? id : v4()
  return {
    id: validatedId,
    storeURL: { path: storeURL.path },
    sourceURL: { path: sourceURL.path },
    logo: logo.path,
    screenshots: screenshots.map(it => it.path),
    date: Timestamp.fromDate(date),
    ...rest,
  }
}

const fromFirebase = (project: any): Project => {
  const { storeURL, sourceURL, logo, screenshots, date, ...rest } = project
  return {
    storeURL: new Url(storeURL.path),
    sourceURL: new Url(sourceURL.path),
    logo: new Url(logo),
    screenshots: screenshots.map((it: any) => new Url(it)),
    date: date?.toDate() ?? new Date(),
    ...rest,
  }
}

const uploadImages = async (
  project: Project,
  collection_: string,
  document_: string,
): Promise<Project> => {
  const blobs = extractBlobs(project)
  for (const entry of blobs.entries()) {
    const blobUrl = entry[0]
    const filePath = `${collection_}${document_}/${v4() + ".jpg"}`
    const fileBuffer = (await blobUrlToBuffer(blobUrl)) as ArrayBuffer
    const downloadUrl = (await uploadImage(filePath, fileBuffer)) as string
    blobs.set(blobUrl, downloadUrl)
  }
  return replaceBlobs(project, blobs)
}

const extractBlobs = (project: Project): Map<string, string> => {
  const blobMap = new Map<string, string>()
  const images = [project.logo, ...project.screenshots]
  images
    .map(it => it.path)
    .filter(it => isBlobUrl(it))
    .forEach(it => blobMap.set(it, ""))
  return blobMap
}

const replaceBlobs = (
  project: Project,
  blobs: Map<string, string>,
): Project => {
  const get = (key: string) =>
    isEmpty(blobs.get(key)) ? key : blobs.get(key) ?? ""
  return {
    ...project,
    logo: new Url(get(project.logo.path)),
    screenshots: project.screenshots.map(it => new Url(get(it.path))),
  }
}
