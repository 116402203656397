import { Employment, SubEmployment } from "@home/domain"
import { firestoreApp } from "@config/firebase"
import {
  collection,
  setDoc,
  updateDoc,
  query,
  getDocs,
  getDoc,
  doc,
  deleteDoc,
  Timestamp,
} from "firebase/firestore"
import { v4, validate } from "uuid"
import { isBlobUrl, blobUrlToBuffer } from "@/blob-to-file"
import { deleteStored, uploadImage } from ".."
import { isEmpty } from "lodash"

export interface EmploymentStorage {
  getById: (id: string) => Promise<Employment | Error>
  getAll: () => Promise<Employment[] | Error>
  create: (employment: Employment) => Promise<Employment | Error>
  update: (employment: Employment) => Promise<Employment | Error>
  delete: (employment: Employment[]) => Promise<void | Error>
}

export const createEmploymentStorage = (
  collection: string = "/home/content",
  document: string = "/employments",
): EmploymentStorage => ({
  getById: async (id: string) => await getById(id, collection, document),
  getAll: async () => await getAll(collection, document),
  create: async (employment: Employment) =>
    await create(employment, collection, document),
  update: async (employment: Employment) =>
    await update(employment, collection, document),
  delete: async (emlpoyment: Employment[]) =>
    await deletes(emlpoyment, collection, document),
})

const getById = async (
  id: string,
  collection_: string,
  document_: string,
): Promise<Employment | Error> => {
  const docRef = doc(firestoreApp(), collection_, document_, id)
  try {
    const docSnapshot = await getDoc(docRef)
    return fromFirebase(docSnapshot.data())
  } catch (error) {
    return Error(error.message)
  }
}

const getAll = async (
  collection_: string,
  document_: string,
): Promise<Employment[] | Error> => {
  const queryRef = query(collection(firestoreApp(), collection_, document_))
  try {
    const docSnapshot = await getDocs(queryRef)
    return docSnapshot.docs.map(doc => doc.data()).map(fromFirebase)
  } catch (error) {
    return Error(error.message)
  }
}

const create = async (
  employment: Employment,
  collection_: string,
  document_: string,
): Promise<Employment | Error> => {
  const collRef = collection(firestoreApp(), collection_, document_)
  try {
    const newEmployemnt = await uploadImages(employment, collection_, document_)
    const document = toFirebase(newEmployemnt)
    const docRef = doc(collRef, document.id)
    await setDoc(docRef, document)
    return fromFirebase(document)
  } catch (error) {
    return Error(error)
  }
}

const update = async (
  employment: Employment,
  collection_: string,
  document_: string,
): Promise<Employment | Error> => {
  const docRef = doc(firestoreApp(), collection_, document_, employment.id)
  try {
    const dbEmployment = fromFirebase((await getDoc(docRef)).data())
    const dbIcons = [
      dbEmployment.employerIcon.path,
      ...dbEmployment.subEmployments.map(it => it.employerIcon.path),
    ]

    const icons = [
      employment.employerIcon.path,
      ...employment.subEmployments.map(it => it.employerIcon.path),
    ]
    const deleteIcons = dbIcons
      .filter(it => !icons.includes(it))
      .filter(it => !isBlobUrl(it))

    await Promise.all(deleteIcons.map(async it => await deleteStored(it)))

    const updatedEmployement = await uploadImages(
      employment,
      collection_,
      document_,
    )

    const doc = toFirebase(updatedEmployement)
    await updateDoc(docRef, doc)
    const from = fromFirebase(doc)
    return from
  } catch (error) {
    return Error(error)
  }
}

const deletes = async (
  employments: Employment[],
  collection_: string,
  document_: string,
) => {
  for (const employment of employments) {
    const docRef = doc(firestoreApp(), collection_, document_, employment.id)
    try {
      const employment_ = fromFirebase((await getDoc(docRef)).data())
      try {
        await Promise.all(
          [
            employment_.employerIcon.path,
            ...employment_.subEmployments.map(it => it.employerIcon.path),
          ].map(async it => await deleteStored(it)),
        )
      } catch (error) {
        console.error(error)
      }
      await deleteDoc(docRef)
    } catch (error) {
      return Error(error)
    }
  }
}

const toFirebase = (employment: Employment) => {
  const { id, fromDate, tillDate, subEmployments, ...rest } = employment
  const validatedId = validate(id) ? id : v4()
  return {
    id: validatedId,
    subEmployments: Object.fromEntries(
      new Map(subEmployments.map(toFirebaseSub).map(item => [item.id, item])),
    ),
    fromDate: Timestamp.fromDate(fromDate),
    tillDate: Timestamp.fromDate(tillDate),
    ...rest,
  }
}

const toFirebaseSub = (subEmployment: SubEmployment) => {
  const { id, fromDate, tillDate, ...rest } = subEmployment
  const validatedId = validate(id) ? id : v4()
  return {
    id: validatedId,
    fromDate: Timestamp.fromDate(fromDate),
    tillDate: Timestamp.fromDate(tillDate),
    ...rest,
  }
}

const fromFirebase = (employment: any): Employment => {
  const { fromDate, tillDate, subEmployments, ...rest } = employment
  return {
    ...rest,
    subEmployments: Object.values(subEmployments).map(fromFirebaseSub),
    fromDate: fromDate.toDate(),
    tillDate: tillDate.toDate(),
  }
}

const fromFirebaseSub = (subEmployment: any): SubEmployment => {
  const { fromDate, tillDate, ...rest } = subEmployment
  return {
    ...rest,
    fromDate: fromDate.toDate(),
    tillDate: tillDate.toDate(),
  }
}

const uploadImages = async (
  employment: Employment,
  collection_: string,
  document_: string,
): Promise<Employment> => {
  const blobs = extractBlobs(employment)
  for (const entry of blobs.entries()) {
    const blobUrl = entry[0]
    const filePath = `${collection_}${document_}/${v4() + ".jpg"}`
    const fileBuffer = (await blobUrlToBuffer(blobUrl)) as ArrayBuffer
    const downloadUrl = (await uploadImage(filePath, fileBuffer)) as string
    blobs.set(blobUrl, downloadUrl)
  }
  return replaceBlobs(employment, blobs)
}

const extractBlobs = (employment: Employment): Map<string, string> => {
  const blobMap = new Map<string, string>()
  if (
    isBlobUrl(employment.employerIcon.path) &&
    employment.employerIcon.path !== ""
  )
    blobMap.set(employment.employerIcon.path, "")
  employment.subEmployments
    .map(it => it.employerIcon.path)
    .filter(it => isBlobUrl(it))
    .forEach(it => blobMap.set(it, ""))
  return blobMap
}

const replaceBlobs = (
  employment: Employment,
  blobs: Map<string, string>,
): Employment => {
  const get = (key: string) =>
    isEmpty(blobs.get(key)) ? key : blobs.get(key) ?? ""
  return {
    ...employment,
    employerIcon: { path: get(employment.employerIcon.path) },
    subEmployments: employment.subEmployments.map(sub => ({
      ...sub,
      employerIcon: { path: get(sub.employerIcon.path) },
    })),
  }
}
