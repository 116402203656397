import { storageApp } from "@config/firebase"
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
  UploadTask,
} from "firebase/storage"

export const uploadImage = async (
  remotePath: string,
  file: File | ArrayBuffer,
): Promise<string | Error> =>
  new Promise((resolve, reject) => {
    const storageRef = ref(storageApp(), remotePath)
    try {
      const uploadTask: UploadTask = uploadBytesResumable(storageRef, file, {
        contentType: "image/*",
      })
      uploadTask.on(
        "state_changed",
        _ => {},
        error => {
          reject(error)
        },
        () =>
          getDownloadURL(uploadTask.snapshot.ref).then(resolve).catch(reject),
      )
    } catch (error) {
      return Error(error)
    }
  })

export const deleteImage = async (
  remotePath: string,
): Promise<string | Error> => {
  const storageRef = ref(storageApp(), remotePath)
  try {
    await deleteObject(storageRef)
    return remotePath
  } catch (error) {
    return Error(error)
  }
}

export const deleteStored = async (url: string): Promise<void> =>
  await deleteObject(ref(storageApp(), url))
