import { Introduction } from "@home/domain"
import { defineStore } from "pinia"
import { createIntroductionStorage } from "@home/web-services"
import { successToast, errorToast } from "@/shared/toast-message"

interface IntroductionState {
  introduction: Introduction
  isLoading: boolean
  error?: Error
}

const defaultState: IntroductionState = {
  introduction: new Introduction(),
  isLoading: true,
  error: undefined,
}

const storage = createIntroductionStorage()

export const useIntroductionStore = defineStore("home/web-admin/introduction", {
  state: (): IntroductionState => defaultState,
  actions: {
    async init() {
      await this.fetch()
    },
    async fetch() {
      this.isLoading = true
      this.error = undefined
      try {
        const result = (await storage.get()) as Introduction
        this.introduction = result
      } catch (error) {
        this.error = error
        console.error(error)
      }
      this.isLoading = false
    },
    async update() {
      try {
        const result = await storage.set(this.introduction)
        this.introduction = result
        this.$toast.add(successToast())
      } catch (error) {
        this.$toast.add(errorToast(error))
        console.error(error)
      }
    },
  },
})
