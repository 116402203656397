import { Project, Url } from "@home/domain"
import { defineStore } from "pinia"
import { createProjectStorage } from "@home/web-services"
import { isEmpty } from "lodash"
import { errorToast, successToast } from "@/shared/toast-message"

interface ProjectsStore {
  loaded: undefined | boolean
  projects: Project[]
  selected: Project[]

  item?: Project
  isNewItem: boolean

  error?: Error
}

const defaultState: ProjectsStore = {
  loaded: undefined,
  projects: [],
  selected: [],

  item: undefined,
  isNewItem: false,

  error: undefined,
}

const storage = createProjectStorage()

export const useProjectStore = defineStore("home/web-admin/projects", {
  state: (): ProjectsStore => defaultState,

  getters: {
    isEditDisabled: state => state.selected.length !== 1,
    isDeleteDisabled: state => state.selected.length === 0,
    getProjectById: state => (id: string) =>
      state.projects.find(project => project.id === id),
  },

  actions: {
    async init() {
      await this.fetch()
    },

    async fetch() {
      try {
        const result = (await storage.getAll()) as Project[]
        this.projects = result
        this.error = undefined
        this.loaded = true
      } catch (error) {
        this.error = error
      }
    },

    async newItem() {
      this.isNewItem = true
      this.item = new Project()
      this.selected = []
      this.$router.push({ path: "/admin/projects/new" })
    },

    async editItem() {
      this.isNewItem = false
      this.item = this.selected[0]
      this.selected = []
      this.$router.push({ path: `/admin/projects/${this.item.id}` })
    },

    async deleteItems() {
      await this.delete()
      this.selected = []
    },

    updateImages(images: string[]) {
      this.item! = { ...this.item!, screenshots: images.map(it => new Url(it)) }
    },

    async save() {
      if (this.isNewItem && !isEmpty(this.item)) {
        await this.create(this.item)
      } else if (!this.isNewItem && !isEmpty(this.item)) {
        await this.update(this.item)
      }
      await this.cancel()
    },

    async cancel() {
      this.isNewItem = false
      this.item = undefined
      this.$router.back()
    },

    async create(project: Project) {
      try {
        await storage.create(project)
        this.$toast.add(successToast())
      } catch (error) {
        this.$toast.add(errorToast())
        console.error(error)
      }
    },

    async update(project: Project) {
      try {
        await storage.update(project)
        this.$toast.add(successToast())
      } catch (error) {
        this.$toast.add(errorToast())
        console.error(error)
      }
    },

    async delete() {
      try {
        await storage.delete(this.selected)
        this.projects = this.projects.filter(it => !this.selected.includes(it))
        this.selected = []
        this.$toast.add(successToast())
      } catch (error) {
        this.$toast.add(errorToast())
        console.error(error)
      }
    },
  },
})
