<template>
  <div class="flex gap-4">
    <Button
      class="h-10 w-28"
      label="save"
      severity="info"
      @click="save" />
    <Button
      class="h-10 w-28"
      label="cancel"
      severity="info"
      @click="cancel" />
  </div>
</template>

<script setup lang="ts">
  import Button from "primevue/button"
  import { useProjectStore } from "./projects.store"
  const store = useProjectStore()
  const { save, cancel } = store
</script>
