<template>
  <div class="flex flex-col w-full overflow-scroll p-6">
    <h1 class="text-2xl mb-6 dark:text-zinc-400">Leads section</h1>

    <div class="flex flex-row gap-2 mb-6">
      <Button
        label="Check"
        class="h-10"
        icon="pi pi-info"
        severity="info"
        :disabled="isCheckDisabled"
        size="small"
        @click="details" />

      <Button
        label="Delete"
        class="h-10"
        icon="pi pi-trash"
        severity="danger"
        :disabled="isDeleteDisabled"
        size="small"
        @click="deleteItem" />
    </div>

    <DataTable
      ref="dt"
      dataKey="id"
      :value="leads"
      v-model:selection="selected">
      <Column
        selectionMode="multiple"
        :exportable="false" />
      <Column
        field="email"
        header="From">
      </Column>
      <Column
        field="subject"
        header="Subject">
      </Column>
      <Column
        field="date"
        header="Date"
        sortable>
        <template #body="{ data }">{{ data.date.toDateString() }}</template>
      </Column>
    </DataTable>
  </div>
</template>

<script setup lang="ts">
  import Button from "primevue/button"
  import DataTable from "primevue/datatable"
  import Column from "primevue/column"
  import { useLeadsStore } from "./leads.store"
  import { storeToRefs } from "pinia"
  import { onMounted } from "vue"
  const store = useLeadsStore()
  const { leads, selected, isCheckDisabled, isDeleteDisabled } =
    storeToRefs(store)
  const { details, deleteItem } = store
  onMounted(store.init)
</script>

<style scoped>
  :deep(.p-datatable .p-datatable-tbody > tr > td) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: auto;
    max-width: 20rem;
  }
  :deep(.p-datatable .p-datatable-tbody > .p-datatable-row-expansion > td) {
    padding: 0px;
    margin: 0px;
  }
</style>
