<template>
  <div class="flex gap-4">
    <Button
      class="h-10 w-28"
      label="save"
      severity="info"
      @click="saveItem" />
    <Button
      class="h-10 w-28"
      label="cancel"
      severity="info"
      @click="cancelItem" />
  </div>
</template>

<script setup lang="ts">
  import Button from "primevue/button"
  import { useListStore } from "../list/list.store"

  const store = useListStore()
  const { saveItem, cancelItem } = store
</script>
