import { createAppAuthStorage } from "@home/web-services"
import { defineStore } from "pinia"

interface LoginStore {
  username: string
  password: string
  rememberMe: boolean
  isLoading: boolean
}

const defaultState: LoginStore = {
  username: "",
  password: "",
  rememberMe: false,
  isLoading: false,
}

const storage = createAppAuthStorage()

export const useLoginStore = defineStore("admin/login", {
  state: () => defaultState,
  getters: {},
  actions: {
    async login() {
      this.isLoading = true
      try {
        await storage.signIn(this.username, this.password, this.rememberMe)
        this.$router.replace({ path: "/admin" })
      } catch (error) {
        console.error(error)
      }
      this.isLoading = false
    },
  },
})
