import { Technology, Url } from "@home/domain"
import { v4, validate } from "uuid"
import {
  collection,
  updateDoc,
  query,
  getDocs,
  doc,
  deleteDoc,
  setDoc,
  getDoc,
  orderBy,
} from "firebase/firestore"
import { firestoreApp } from "@config/firebase"
import { blobUrlToBuffer, isBlobUrl } from "@/blob-to-file"
import {
  deleteStored,
  uploadImage,
} from "@/firebase-file-storage/image-file-storage"
import { isEmpty } from "lodash"

interface TechnologiesStorage {
  getAll: () => Promise<Technology[] | Error>
  getById: (id: string) => Promise<Technology | Error>
  create: (technology: Technology) => Promise<Technology | Error>
  update: (technology: Technology) => Promise<Technology | Error>
  updateOrder: (technologies: Technology[]) => Promise<void | Error>
  delete: (technology: Technology[]) => Promise<void | Error>
}

export const createTechnologiesStorage = (
  _collection = "/home/content",
  _document = "/technologies",
): TechnologiesStorage => ({
  getAll: async () => await getAll(_collection, _document),
  getById: async (id: string) => await getById(id, _collection, _document),
  create: async (technology: Technology) =>
    await create(technology, _collection, _document),
  update: async (technology: Technology) =>
    await update(technology, _collection, _document),
  updateOrder: async (technologies: Technology[]) =>
    await updateOrder(technologies, _collection, _document),
  delete: async (technologies: Technology[]) =>
    await deletes(technologies, _collection, _document),
})

async function getAll(
  _collection: string,
  _document: string,
): Promise<Technology[] | Error> {
  const queryRef = query(
    collection(firestoreApp(), _collection, _document),
    orderBy("order"),
  )
  try {
    const docSnapshot = await getDocs(queryRef)
    return docSnapshot.docs.map(doc => doc.data()).map(fromFirebase)
  } catch (error) {
    console.error(error)
    return Error(error.message)
  }
}

async function getById(
  id: string,
  _collection: string,
  _document: string,
): Promise<Technology | Error> {
  const docRef = doc(firestoreApp(), _collection, _document, id)
  try {
    const doc = await getDoc(docRef)
    return fromFirebase(doc.data())
  } catch (error) {
    console.error(error)
    return Error(error.message)
  }
}

async function create(
  technology: Technology,
  _collection: string,
  _document: string,
): Promise<Technology | Error> {
  const collRef = collection(firestoreApp(), _collection, _document)
  try {
    const newProject = await uploadImages(technology, _collection, _document)
    const document = toFirebase(newProject)
    const docRef = doc(collRef, document.id)
    await setDoc(docRef, document)
    return fromFirebase(document)
  } catch (error) {
    console.error(error)
    return Error(error)
  }
}

async function update(
  technology: Technology,
  _collection: string,
  _document: string,
): Promise<Technology | Error> {
  const docRef = doc(firestoreApp(), _collection, _document, technology.id)
  try {
    const dbTechnology = fromFirebase((await getDoc(docRef)).data())
    try {
      if (dbTechnology.icon.path !== technology.icon.path)
        await deleteStored(dbTechnology.icon.path)
    } catch (error) {
      console.error(error)
    }
    const updatedProject = await uploadImages(
      technology,
      _collection,
      _document,
    )
    const doc = toFirebase(updatedProject)
    await updateDoc(docRef, doc)
    const from = fromFirebase(doc)
    return from
  } catch (error) {
    return Error(error)
  }
}

async function updateOrder(
  technologies: Technology[],
  _collection: string,
  _document: string,
): Promise<void | Error> {
  try {
    for (const [index, technology] of technologies.entries()) {
      const docRef = doc(firestoreApp(), _collection, _document, technology.id)
      await updateDoc(docRef, { order: index })
    }
  } catch (error) {
    console.error(error)
  }
}

async function deletes(
  technologies: Technology[],
  _collection: string,
  _document: string,
): Promise<void | Error> {
  for (const technology of technologies) {
    const docRef = doc(firestoreApp(), _collection, _document, technology.id)
    try {
      try {
        await deleteStored(technology.icon.path)
      } catch (error) {
        console.error(error)
      }
      await deleteDoc(docRef)
    } catch (error) {
      console.error(error)
      return Error(error)
    }
  }
  return
}

const toFirebase = (technology: Technology) => {
  const { id, icon, ...rest } = technology
  const validatedId = validate(id) ? id : v4()
  return {
    id: validatedId,
    icon: { path: icon.path },
    ...rest,
  }
}

const fromFirebase = (technology: any): Technology => {
  const { icon, ...rest } = technology
  return {
    icon: new Url(icon.path),
    ...rest,
  }
}

const uploadImages = async (
  technology: Technology,
  collection_: string,
  document_: string,
): Promise<Technology> => {
  const blobs = extractBlobs(technology)
  for (const entry of blobs.entries()) {
    const blobUrl = entry[0]
    const filePath = `${collection_}${document_}/${v4()}.jpg`
    const fileBuffer = (await blobUrlToBuffer(blobUrl)) as ArrayBuffer
    const downloadUrl = (await uploadImage(filePath, fileBuffer)) as string
    blobs.set(blobUrl, downloadUrl)
  }
  return replaceBlobs(technology, blobs)
}

const extractBlobs = (technology: Technology): Map<string, string> => {
  const blobMap = new Map<string, string>()
  const images = [technology.icon]
  images
    .map(it => it.path)
    .filter(it => isBlobUrl(it))
    .forEach(it => blobMap.set(it, ""))
  return blobMap
}

const replaceBlobs = (
  technology: Technology,
  blobs: Map<string, string>,
): Technology => {
  const get = (key: string) =>
    isEmpty(blobs.get(key)) ? key : blobs.get(key) ?? ""
  return {
    ...technology,
    icon: new Url(get(technology.icon.path)),
  }
}
