import { Introduction } from "@home/domain"
import { firestoreApp } from "@config/firebase"
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore"
import { isEmpty } from "lodash"
import { blobUrlToBuffer, isBlobUrl } from "@/blob-to-file"
import { uploadImage } from "@/firebase-file-storage/image-file-storage"
import { v4 } from "uuid"

export interface IntroductionStorage {
  get: () => Promise<Introduction>
  set: (i: Introduction) => Promise<Introduction>
}

export const createIntroductionStorage = (
  _collection: string = "/home/content",
  _document: string = "/introduction/value",
): IntroductionStorage => ({
  get: async () => await get(_collection, _document),
  set: async i => await set(i, _collection, _document),
})

const get = async (
  _collection: string,
  _document: string,
): Promise<Introduction> => {
  const docRef = doc(firestoreApp(), _collection, _document)
  const snapshot = await getDoc(docRef)
  return snapshot.exists()
    ? (snapshot.data() as Introduction)
    : new Introduction()
}

const set = async (
  introduction: Introduction,
  _collection: string,
  _document: string,
): Promise<Introduction> => {
  const docRef = doc(firestoreApp(), _collection, _document)
  const oldIntroduction = (await getDoc(docRef)).data() as Introduction
  if (oldIntroduction === undefined) {
    await setDoc(docRef, { ...introduction })
  } else {
    const updatedIntroduction = await uploadImages(
      introduction,
      _collection,
      _document,
    )
    await updateDoc(docRef, { ...updatedIntroduction })
  }
  return introduction
}

const uploadImages = async (
  introduction: Introduction,
  collection_: string,
  document_: string,
): Promise<Introduction> => {
  const blobs = extractBlobs(introduction)
  for (const entry of blobs.entries()) {
    const blobUrl = entry[0]
    const filePath = `${collection_}${document_}/${v4() + ".jpg"}`
    const fileBuffer = (await blobUrlToBuffer(blobUrl)) as ArrayBuffer
    const downloadUrl = (await uploadImage(filePath, fileBuffer)) as string
    blobs.set(blobUrl, downloadUrl)
  }
  return replaceBlobs(introduction, blobs)
}

const extractBlobs = (introduction: Introduction): Map<string, string> => {
  const blobMap = new Map<string, string>()
  if (isBlobUrl(introduction.photo.path))
    blobMap.set(introduction.photo.path, "")
  return blobMap
}

const replaceBlobs = (
  introduction: Introduction,
  blobs: Map<string, string>,
): Introduction => {
  const get = (key: string) =>
    isEmpty(blobs.get(key)) ? key : blobs.get(key) ?? ""
  return {
    ...introduction,
    photo: { path: get(introduction.photo.path) },
  }
}
