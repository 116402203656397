<template>
  <div>
    <div class="flex gap-4 items-center mb-4 ml-6">
      <v-icon
        name="fa-briefcase"
        scale="1.5"
        class="text-zinc-400" />
      <h2 class="text-sm font-semibold text-zinc-900 dark:text-zinc-100">
        Projects
      </h2>
    </div>
    <ul
      role="list"
      class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 cursor-pointer">
      <li
        v-for="project of props.data"
        :key="project.id"
        @click="goToProjectDetails(project)">
        <project-item :data="project" />
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
  import { Project } from "@home/domain"
  import projectItem from "./project-item.vue"
  import { useHomePageStore } from "../home.store"
  const props = defineProps<{ data: Project[] }>()
  const store = useHomePageStore()
  const { goToProjectDetails } = store
</script>
